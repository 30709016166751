import React from 'react';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';

const SarasPopup = (props) => {
  const { anchorRef, open, onClose, paperStyle, placement } = props;

  const handleClose = (event) => {
    if(onClose) onClose();
  };

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(open);

  React.useEffect(() => {
    // console.log("popup status", open);
    if (prevOpen.current === true && open === false && anchorRef) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open, anchorRef]);

    return (
      <Popper style={{zIndex: 100000,width:"fit-content"}} disablePortal={false} 
        open={open} anchorEl={anchorRef && anchorRef.current} 
        placement={placement || 'bottom-start'} transition>

        {({ TransitionProps }) => (
            <Grow
            {...TransitionProps}
            >
            <ClickAwayListener onClickAway={handleClose}>
                <Paper elevation={3} style={paperStyle}>
                    {props.children}
                </Paper>
            </ClickAwayListener>
            </Grow>
        )}

      </Popper>
    )
}

export default SarasPopup;

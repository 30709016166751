import React from "react";

const UiBox = (props) => {
    const { children } = props
    return (
        <div className="uiRowBoxContainer">
            {children}
        </div>
    )
}

const UiBox2 = (props) => {
    const { children } = props
    return (
        <div className="uiRowBoxContainer2">
            {children}
        </div>
    )
}

export {UiBox, UiBox2}
import React from 'react';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({

    control: {
        padding: theme.spacing(2),
  }

}));

const SarasGridContainer = (props) => {
    const classes = useStyles();

    return(
        <Grid container className={classes.root} spacing={props.spacing || 2} direction={props.direction || "row"}>
            {props.children}
        </Grid>
    )
}

export default SarasGridContainer
import React from 'react';

const UiView = (props) => {
    const { children, className } = props;
    return (
        <div className={`phViewArea ${className}`}>
            {children}
        </div>
    )
}

const UiViewHeading = (props) => {
    const { children, className } = props;
    return (
        <div className={`phViewHeading ${className}`}>
            {children}
        </div>
    )
}

const UiTitle = (props) => {
    const { children } = props;
    return (
        <div className="phTitle">
            {children}
        </div>
    )
}

const UiAction = (props) => {
    const { children } = props;
    return (
        <div className="phAction">
            {children}
        </div>
    )
}

const UiViewContent = (props) => {
    const { children, style } = props;
    return (
        <div className="phViewContent" style={style}>
            {children}
        </div>
    )
}

const UiClear = (props) => {
    const { children } = props;
    return (
        <div className="uiResetFloats">
            {children}
        </div>
    )
}



export { UiView, UiViewHeading, UiTitle, UiAction, UiViewContent, UiClear };
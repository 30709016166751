import React from 'react'
import DocSearchFavouriteRow from './DocSearchFavouriteRow'
import SarasMenu from '../../../../library/repeater/menu/SarasMenu'

const DocSearchFavourite = (props) => {
    const { viewData, onFavSelected } = props;

    // console.log('favorites data', viewData)

    const renderFavMenu = () => {
        return (
            <div title="Select from Favourite" className="uiActionSelFav uiHoverRound"></div>
        )
      }
  
    if(!viewData)
        return(null)  

    return (
        <SarasMenu menuTrigger={renderFavMenu()} 
        dataList={Object.values(viewData)} 
        menuItem={DocSearchFavouriteRow} onSelection={onFavSelected}></SarasMenu>
)
}

export default DocSearchFavourite
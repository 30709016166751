import React from 'react';
import Grid from '@material-ui/core/Grid';

const SarasGridItem = (props) => {
    return(
        <Grid item xs={props.xs}>
            {props.children}
        </Grid>
    )
}

export default SarasGridItem
import React from "react";

const SarasDD = (props) => {
    const {ctrlRef, ctrlID, ctrlClass, ctrlStyle, 
        dataList, currentValue, onChange} = props;

    // const [ddValue, setDDValue] = React.useState(currentValue);
        
    // console.log("dd choices", ctrlID, ddValue);
        
    const selectValueChanged = (e) => {
        //return the selected value
        let value = e.currentTarget.value;
        // setDDValue(value);
        if(onChange) onChange(e, value);
    }

    return (
        <select ref={ctrlRef} id={ctrlID} className={ctrlClass} style={ctrlStyle}
        onChange={selectValueChanged} value={currentValue}>
            {
                dataList.map((row, index) => (
                    <option key={index} value={row.key}>{row.val}</option>
                ))
            }
        </select>
    )
}

export default SarasDD;

import React, {useEffect} from 'react'
import DocSearchCriteriaRow from './DocSearchCriteriaRow'
import DocSearchSort from './DocSearchSort'
import DocSearchFavourite from './DocSearchFavourite'
import SarasSearchAC from '../../../../library/input/autocomplete/SarasSearchAC'
import SarasTrans from '../../../../library/notifications/SarasTrans'
import ActionButton from '../../../../library/input/buttons/button/SarasButton2';
import { getSearchFieldsMatchingParam, getSearchFieldSourceByDataType, buildSearchCriteria } from './DocSCFunctions'

const DocSearchCriteria = (props) => {
    const { viewData, scList, sortList, favList, currentSortKey, addSearchCriteria, onShowBirdEyeView, onSortSelected,
    onCriteriaAction, onFavSelected, onSaveFavorite, onSearchRequested, enableSort, enableSearch, enablefav, enableBirdEyeView } = props;

    const [stayFocused, setStayFocused] = React.useState(0);
    const addFavRef = React.useRef(null);
    const showBirdEyeRef = React.useRef(null);
    const inputRef = React.useRef();
    const stayfocusedRef = React.useRef();

    stayfocusedRef.current = stayFocused;

    let initialiseScanner = function() {
            // console.log("scanner listen");

            document.addEventListener('onScan', onScan, true);

        return function () {
            document.removeEventListener('onScan', onScan, true);
        }
    }

    useEffect(initialiseScanner, []);

    const inputHaveFocus = () =>{
        if (inputRef && document.activeElement === inputRef.current) {
            return true;
        }

        return false;
    }

    const onScan =  (e) => {
        console.log("scan event captured 1", e.detail.msg);
        //add the selected criteria to scList list
        if (e.detail.msg.length === 0) return;

        if(!e.detail.haveMultipleCapture || inputHaveFocus()){
            let fieldKey = null;

            //get the first available scanner compatible field
            let list = getSearchFieldSourceByDataType("scan", Object.values(viewData.childViews.fieldView.viewRows));

            if(list && list.length > 0){
                fieldKey = list[0].rowKey;
                
                if (!fieldKey) return;

                let newCriteria = buildSearchCriteria(null, fieldKey, "", e.detail.msg, "", viewData.childViews.fieldView.viewRows);
        
// console.log("scan qr criteria", newCriteria);

                if (addSearchCriteria && newCriteria) addSearchCriteria(newCriteria);

                setStayFocused(stayfocusedRef.current + 1);
            }

            // setSearchParam("");
        }
    }

    const saveFavClicked = () => {
        if (onSaveFavorite) onSaveFavorite(addFavRef);
    }

    const onSearchClick = () => {
        if (onSearchRequested) onSearchRequested();
    }

    const onBirdEyeViewClick = () => {
        if (onShowBirdEyeView) onShowBirdEyeView(showBirdEyeRef);
    }

    const onRefreshClick = () => {
        if (onSearchRequested) onSearchRequested(1);
    }

    const onSelectField = (sp, fieldKey) => {
        // console.log("criteria selected", fieldKey, searchParam, scList);
        //add the selected criteria to scList list
        if (sp.length === 0) return;
        let newCriteria = buildSearchCriteria(null, fieldKey, "", sp, "", viewData.childViews.fieldView.viewRows);

        if (addSearchCriteria && newCriteria) addSearchCriteria(newCriteria);

        // setSearchParam("");
    }

    const onSearchParamChange = (sp) => {
        // // console.log("searching for matching fields", sp);
        // setSearchParam(sp);

        //return the matching fields for the given search param value
        return getSearchFieldsMatchingParam(sp, Object.values(viewData.childViews.fieldView.viewRows));
    }

    const haveDateFieldFilter = () => {
        let list = getSearchFieldSourceByDataType("datetime", Object.values(viewData.childViews.fieldView.viewRows));

        return (list && list.length > 0);
    }

    const RenderSearchAndRefresh = () => {
        if (enableSearch) {
            return (
                <div className="uiInline uiGroupControl10">
                    <ActionButton onClick={onSearchClick} text="Search" icon="search" 
                        tooltip="Search Again"
                        className="uiActionSearch uiHoverRound"/>
                    <ActionButton ksc="r" onClick={onRefreshClick} text="Reload Data" 
                        tooltip="Reload Search Data from Database"
                        icon="refresh" className="uiActionRefresh uiHoverRound" />
                </div>
            )
        }
        else
            return null;
    }

    const RenderSort = () => {
        // console.log("enableSort", enableSort)
        if (enableSort !== false) {
            return (
                <div className="uiInline uiGroupControl10">
                    <DocSearchSort currentKey={currentSortKey} onSortSelected={onSortSelected} viewData={sortList} />
                </div>
            )
        }
        else
            return null;
    }

    const RenderFav = () => {
        // console.log("enablefav", enablefav)
        if (enablefav !== false) {
            return (
                <div ref={addFavRef} className="uiInline uiGroupControl10">
                    <ActionButton ksc="f" onClick={saveFavClicked} text="Add Current Criterias to Favourite" icon="star" 
                        tooltip="Add current criteria to favorite"
                        className="uiActionAddFav uiHoverRound"/>
                    <DocSearchFavourite onFavSelected={onFavSelected} viewData={favList} />
                </div>
            )
        }
        else
            return null;
    }

    const RenderBirdEyeView = () => {
        if (enableBirdEyeView === "1") {
            return (
                <div ref={showBirdEyeRef} className="uiInline uiGroupControl10">
                    <ActionButton ksc="b" onClick={onBirdEyeViewClick} text="Show Bird Eye View" icon="birdeye" 
                        tooltip="Show summary"
                        className="uiActionAddFav uiHoverRound"/>
                </div>
            )
        }
        else
            return null;
    }

    return (
        <div style={{ display: 'inline-block' }} className="frmSearchCriteria">
            <div className="frmSearch" style={{ position: 'relative' }}>
                <div className="uiSelectedCriteria" >
                    <ul>
                        <li><SarasTrans value="Criteria" /></li>
                        {
                            Object.values(scList).map((row, index) => (
                                <DocSearchCriteriaRow viewData={row} rowKey={row.rowKey} key={index}
                                    onCriteriaAction={onCriteriaAction} />
                            ))
                        }
                    </ul>
                </div>
                <div className="uiSearchInput uiVAlign">
                    <SarasSearchAC 
                        inputRef={inputRef}
                        onSearchParamChange={onSearchParamChange} 
                        haveDateFieldFilter={haveDateFieldFilter}
                        onSelection={onSelectField}
                        resetSearchParam={stayFocused} />
                </div>
                <div className="uiSearchAction uiVAlign">
                    {
                        RenderSearchAndRefresh()
                    }
                    {RenderFav()}
                    {RenderSort()}
                    {RenderBirdEyeView()}
                </div>
            </div>

        </div>
    )
}

export default DocSearchCriteria
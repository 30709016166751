import React from 'react'
import { Chart } from "react-google-charts";

const InsightPieGraphView =(props)=>{
    const {viewData, activeOption} = props

    const currentOption = activeOption;// || viewData.DefaultOption;
    
    // console.log("layout response view", viewData.ViewData[currentOption].ChartData);

    if(viewData && viewData.ViewData[currentOption] && viewData.ViewData[currentOption].ChartData)
        return (
            <Chart
                chartType="PieChart"
                data={viewData.ViewData[currentOption].ChartData}
                options={{
                    title: viewData.Title,
                    colors: ["#1b9e77", "#d95f02", "#7570b3"],
                    bar: { groupWidth: "75%" },
                    legend: { position: "bottom" },
                    hAxis: { title: viewData.X_AXIS_Title },
                    vAxis: { title: viewData.ViewData[currentOption].Y_AXIS_Title }
                }}
                width="100%"
                height="100%"
            />
        )

    return null;
}

export default InsightPieGraphView

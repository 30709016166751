import React from 'react';
import Insights from '../../../view/cmn/insight/Insights';
// import InsightsSample from './InsightsSample';

const Home = () => {
    return (
        <>
            <Insights></Insights>
        </>
    )
}

export default Home;
import React, { useRef, useEffect } from 'react'
import DocSearchCriteria from './DocSearchCriteria'
import DocCriteriaEditor from './DocCriteriaEditor'
import DocSearchGroups from './DocSearchGroups'
import DocSearchDynamicGroup from './DocSearchDynamicGroup'
import DocSearchSaveFavorite from './DocSearchSaveFavorite'
import DocSearchBirdEyeView from './DocSearchBirdEyeView'
import { buildCriteriaValueFromJsonList, buildSearchCriteria, saveFavCriteria, deleteFavourite, buildSearchCriteriaFromString } from './DocSCFunctions'
import { UiGroupHeader, UiGroupContent } from "../../../../library/layout/Index";
import * as formJS from '../../docAutomation/formFunctions';

const DocSearch = (props) => {
    const { viewData, onSearchByGroup, defaultGroup, onSearchByCriteria, onChangeSort, defaultSort,
        shortcutSearchRequested, birdEyeViewData, onBirdEyeView, 
        onSearchByDynamicGroup, onChangeDynamicGroup, defaultDynamicGroup, dynamicGroupChoiceData,
        enableSearch, enableGroups, enablefav, rptKey, actionName, disableSearch } = props

    const sortList = viewData.childViews.sortView ? viewData.childViews.sortView.viewRows : {};
    const dynamicGroupList = viewData.childViews.dynamicGroupView ? viewData.childViews.dynamicGroupView.viewRows : {};
    const favouriteList = viewData.childViews.favView ? viewData.childViews.favView.viewRows : {};
    const enableBirdEyeView = formJS.getValueFromKey(viewData, "childViews.resultView.dataList.enableBirdEyeView", "0");

    // console.log("DocSearch",viewData,favouriteList)
    const [scList, setSCList] = React.useState(viewData.childViews.criteriaView.viewRows);
    const [favList, setFavList] = React.useState(favouriteList);//viewData.favourites
    const [popupName, setPopupName] = React.useState('');
    const [popupAnchor, setPopupAnchor] = React.useState(null);
    const [currentCriteria, setCurrentCriteria] = React.useState({});
    const [currentGroupKey, setCurrentGroupKey] = React.useState("");
    const [currentSortKey, setCurrentSortKey] = React.useState("");
    const [currentDynamicGroupKey, setCurrentDynamicGroupKey] = React.useState("");

    const scListRef = useRef();

    scListRef.current = scList;

    const updateShortcutSearchRequest = () => {
        //open criteria editor
        // console.log("short cut search request", shortcutSearchRequested);
        if (shortcutSearchRequested && shortcutSearchRequested.fieldKey && shortcutSearchRequested.targetRef) {
            // console.log("short cut search request", shortcutSearchRequested);
            setCurrentCriteria(null);
            setPopupAnchor(shortcutSearchRequested.targetRef);
            setPopupName("criteriaEditor");
        }
    }

    //ensure state is updated whenever the props are changed by parent
    useEffect(updateShortcutSearchRequest, [shortcutSearchRequested]);
    
    const updateDefaultCriteria = () => {
        if (defaultGroup) {
            setCurrentGroupKey(defaultGroup);
        }
    }

    useEffect(updateDefaultCriteria, [defaultGroup]);

    const updateDefaultSort = () => {
        if (defaultSort) {
            setCurrentSortKey(defaultSort);
        }
    }

    useEffect(updateDefaultSort, [defaultSort]);

    const updateDefaultDynamicGroup = () => {
        if (defaultDynamicGroup) {
            setCurrentDynamicGroupKey(defaultDynamicGroup);
        }
    }

    useEffect(updateDefaultDynamicGroup, [defaultDynamicGroup]);

    const updateCriteriaStateFromProps = () => {
        // console.log("criteria list changed");
        setSCList(viewData.childViews.criteriaView.viewRows);
    }

    //ensure state is updated whenever the props are changed by parent
    useEffect(updateCriteriaStateFromProps, [props.viewData.childViews.criteriaView]);

    const updateFavStateFromProps = () => {
        if(viewData.childViews.favView)
            setFavList(viewData.childViews.favView.viewRows);
        else    
            setFavList({});
    }

    //ensure state is updated whenever the props are changed by parent
    useEffect(updateFavStateFromProps, [props.viewData.childViews.favView]);

    const onSearchRequested = (reload, criteriaList) => {
        if (criteriaList === undefined) criteriaList = scList;

        // console.log("search requested");

        let criteriaValue = buildCriteriaValueFromJsonList(criteriaList);

        if (onSearchByCriteria) onSearchByCriteria(reload, criteriaValue);
    }

    const addSearchCriteria = (newCriteria) => {
        // console.log('new criteria', criteria);
        let newSCList = { ...scListRef.current }
        newSCList[newCriteria.rowKey] = newCriteria;
        // console.log('after adding to the new', newSCList);
        // console.log('scan criteria list', scList, newSCList);
        
        setSCList({ ...newSCList });

        //fire search
        onSearchRequested(1, newSCList);
    }

    const updateCriteriaChanges = (criteriaObj, newField, newCond, newVal1, newVal2) => {
        // console.log("criteria updated", criteriaObj, newField, newCond, newVal1, newVal2);

        //TODO update criteria
        //if criteriaObj is available it means existing criteria is being edited
        //else create a new criteria object

        //do not build if the new value is blank
        if(!newVal1) return;

        let newCriteria = buildSearchCriteria(criteriaObj, newField, newCond, newVal1, newVal2, viewData.childViews.fieldView.viewRows);
        if (newCriteria) addSearchCriteria(newCriteria);

        //remove popup
        setCurrentCriteria(null);
        setPopupAnchor(null);
        setPopupName("");
    }

    const onSearchGroupChange = (groupKey) => {
        //retain the last selection
        setCurrentGroupKey(groupKey);

        if (onSearchByGroup) onSearchByGroup(groupKey);
    }

    const cancelCriteriaChanges = () => {
        // console.log("criteria canceled");
        //remove popup
        setCurrentCriteria(null);
        setPopupAnchor(null);
        setPopupName("");
    }

    const deleteCriteria = (fieldKey, isPopup) => {
        //delete popup
        //delete criteria
        let newSCList = { ...scList }

        // console.log('current criteria list', newSCList);
        delete newSCList[fieldKey];
        // console.log('criteria list after delete', newSCList);

        setSCList({ ...newSCList });

        //fire search
        onSearchRequested(1, newSCList);
        
        if(isPopup)
        {
            setCurrentCriteria(null);
            setPopupAnchor(null);
            setPopupName("");
        }
    }

    const onCriteriaAction = (anchorRef, fieldKey, action) => {
        //open criteria editor or delete criteria
        // console.log("on criteria clicked", anchorRef, rowKey, action);

        if (action === "action-delete") {
            deleteCriteria(fieldKey, false);
        }
        else {
            //open criteria editor
            setCurrentCriteria(scList[fieldKey]);
            setPopupAnchor(anchorRef);
            setPopupName("criteriaEditor");
        }
    }

    const onCallSaveFavorite = (anchorRef) => {
        setPopupAnchor(anchorRef);
        setPopupName("addFav");
    }

    const onShowBirdEyeView = (anchorRef) => {
        if(popupName === "showBirdEyeView"){
            setPopupAnchor(null);
            setPopupName("");
        }
        else{
            //trigger server request
            if (onBirdEyeView) onBirdEyeView();

            //show the popup
            setPopupAnchor(anchorRef);
            setPopupName("showBirdEyeView");
        }
    }

    const onSortSelected = (key) => {
        setCurrentSortKey(key);

        if(onChangeSort) onChangeSort(key);
    }

    const onDynamicGroupSelected = (key) => {
        setCurrentDynamicGroupKey(key);

        if(onChangeDynamicGroup) onChangeDynamicGroup(key);
    }

    const onFavSelected = (e, index) => {
        // console.log("props in fav click event", e.currentTarget.classList, e.target.classList, index);
        //let favList = viewData.childViews.favView.viewRows;
        let favRowClicked = favList[Object.keys(favList)[index]];
        // console.log("fav row object", favRowClicked, favList);
        if (!favRowClicked || favRowClicked.length === 0) return;
        let favCriteriaVal = favRowClicked.dataList.cv;
        let classClicked = e.currentTarget.classList;

        if (classClicked.contains('action-delete')) {
            //fire delete favorite
            // console.log("fire delete fav", favRowClicked);
            deleteFavourite(favRowClicked.rowKey, viewData.masterKeys.dtId, currentGroupKey, onFavSuccessCallBack, rptKey, actionName)
        }
        else {
            //fire search by favorite
            // console.log("fire search by fav", favRowClicked.rowKey);

            let newSCList = buildSearchCriteriaFromString(favCriteriaVal, viewData.childViews.fieldView.viewRows)
            setSCList({ ...newSCList });
            if (onSearchByCriteria) {
                onSearchRequested(1, newSCList);
            } //onSearchByFavorite(favRowClicked.rowKey,favCriteriaVal);
        }
    }

    const onSaveFavorite = (name) => {
        if (scList !== undefined) {
            let criteriaValue = buildCriteriaValueFromJsonList(scList);

            saveFavCriteria(criteriaValue, name, viewData.masterKeys.dtId, currentGroupKey, onFavSuccessCallBack, rptKey, actionName)
            /*newFav.criteria = [...scList]
            list.push({ ...newFav })
            setFavList([...list])*/
            // console.log('testinh hhh',list)
        }
    }

    const onFavSuccessCallBack = (response) => {
        // console.log('fav response', response)
        if (response)
            setFavList(response.data.mainViewData.viewRows)
    }

    const renderPopup = () => {
        if (popupAnchor == null) return null;

        if (popupName === 'addFav') {
            return (<DocSearchSaveFavorite targetRef={popupAnchor} addFav={onSaveFavorite} onCancel={setPopupName} />);
        }
        else if (popupName === "showBirdEyeView"){
            return (<DocSearchBirdEyeView targetRef={popupAnchor} birdEyeViewData={birdEyeViewData} onCancel={setPopupName} />);
        }
        else if (popupName === "criteriaEditor") {
            //shortcutSearchRequested.fieldKey is used when creteria is asses from tanle header
            return (
                <DocCriteriaEditor targetRef={popupAnchor} criteriaObj={currentCriteria}
                    criteriaKey={shortcutSearchRequested ? shortcutSearchRequested.fieldKey : null}
                    fields={viewData.childViews.fieldView.viewRows}
                    conditions={viewData.childViews.conditionView.viewRows}
                    onUpdate={updateCriteriaChanges}
                    onCancel={cancelCriteriaChanges}
                    onDelete={deleteCriteria}
                />
            )
        }

        return null;
    }

    const RenderSearchGroups = () => {
        if (enableGroups) {
            return (
                <UiGroupHeader>
                    <DocSearchGroups viewData={viewData.childViews.groupView.viewRows}
                        previousGroupKey={currentGroupKey}
                        onSearchGroupChange={onSearchGroupChange} />
                </UiGroupHeader>
            )
        }
        else
            return null
    }

    const RenderSearchDynamicGroups = () => {
        if (enableGroups && dynamicGroupList && Object.keys(dynamicGroupList).length > 0) {
            return (
                <UiGroupContent>
                    <DocSearchDynamicGroup 
                        currentKey={currentDynamicGroupKey} 
                        onGroupSelected={onDynamicGroupSelected} 
                        onSearchByDynamicGroup={onSearchByDynamicGroup}
                        dynamicGroupChoiceData={dynamicGroupChoiceData}
                        dynamicGroupData={dynamicGroupList} />
                </UiGroupContent>
            )
        }
        else
            return null
    }

    if(disableSearch){
        return(null);
    }

    return (
        <>
            <div className="uiCMNSearchHeader">
                <div id="SearchMain"  className="phSearchPanel">
                    {
                        RenderSearchGroups()
                    }
                    <UiGroupContent>
                        <div id="phSearchCriteria" className="uiSearchCriteria">
                            <DocSearchCriteria viewData={viewData}
                                scList={scList} favList={favList} sortList={sortList}
                                addSearchCriteria={addSearchCriteria}
                                onFavSelected={onFavSelected}
                                onSortSelected={onSortSelected}
                                onSaveFavorite={onCallSaveFavorite}
                                onCriteriaAction={onCriteriaAction}
                                onSearchRequested={onSearchRequested}
                                onShowBirdEyeView={onShowBirdEyeView}
                                currentSortKey={currentSortKey}
                                enableSearch={enableSearch}
                                enablefav={enablefav}
                                enableBirdEyeView={enableBirdEyeView}
                            />
                        </div>
                    </UiGroupContent>
                    {
                        RenderSearchDynamicGroups()
                    }
                </div>
            </div>
            {renderPopup()}
        </>
    )
}

export default DocSearch

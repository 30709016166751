import React from 'react'

const DocSearchDynamicGroupChoiceRow = (props) => {
    const { viewData, onItemAction } = props;
    const anchorRef = React.useRef(null);

    const onMouseEnter = (e) => {
        if(onItemAction) onItemAction(anchorRef, viewData);
    }

    return (
        <>
            <span ref={anchorRef} onMouseEnter={onMouseEnter} >{viewData.val}</span>
        </>
    )
}

export default DocSearchDynamicGroupChoiceRow
import React, { useEffect } from 'react'
import { buildGroupsKeyValueArray } from './DocSCFunctions'
import SarasTabs from '../../../../library/repeater/tabs/SarasTabs';

const DocSearchGroups = (props) => {
    const { viewData, previousGroupKey, onSearchGroupChange } = props;
    const [groupList, setGroupList] = React.useState([]);
    const [currentGroupKey, setCurrentGroupKey] = React.useState(previousGroupKey);

    console.log("test", groupList);
    
    //execute whenever field dt changes - effectively this also will run only once
    const initialiseOnDataChange = () => {
        //get the fields list key value pair for the given field dt
        let groupsKeyVal = buildGroupsKeyValueArray(viewData);
        setGroupList(groupsKeyVal.list);

        //set default only when there is no other group selected
        if(!currentGroupKey || currentGroupKey !== (previousGroupKey || groupsKeyVal.defaultKey)){
            setCurrentGroupKey(previousGroupKey || groupsKeyVal.defaultKey);
        }
        // console.log("search groups", groupsKeyVal);
    }

    //execute whenever field dt changes - effectively this also will run only once
    useEffect(initialiseOnDataChange, [viewData, previousGroupKey]);

    const onGroupChange = (tabKey) => {
        setCurrentGroupKey(tabKey);
        // console.log("search group changed", tabKey);
        //fire search request
        if(onSearchGroupChange) onSearchGroupChange(tabKey);
    }

    if(!groupList || groupList.length === 0){
        return null;
    }
    else {
        return (
            <SarasTabs dataList={groupList} onChange={onGroupChange} defaultKey={currentGroupKey} enableHotKeys={false} />
        )
    }
}

export default DocSearchGroups;
import { SIGN_IN, CHECK_SIGN_STATE, SWITCH_COMPANY } from "../actions/types";
import {getFormFieldValue} from "../../../../helper/uiFunctions";

const INITIAL_STATE = {
    isSignedIn: null,
    user: null,
    server: {MaintenanceModeFlag: 0, SessionTimeOutMin: -1, DeveloperModeFlag: 0, DebugModeFlag: 0, 
            InvalidPrivateKeyFlag: 0, ForceRollbackFlag: 0, ProfileName:"", PackageCode: "", 
            ClientCode: "", AppVersion: "", DeviceIntegrated: null,
            InsightsActive: 0}
}

export default (state = INITIAL_STATE, action) => {
    if(action.type === SIGN_IN || action.type === CHECK_SIGN_STATE || action.type === SWITCH_COMPANY)
    {
        // console.log("user login response received", action, action.payload.isSignedIn, action.payload.isError);
        if(action.payload && action.payload.isSignedIn === 1 && action.payload.isError === 0){
            const userObject = getFormFieldValue(action.payload.dataList, "us");
            const serverObject = getFormFieldValue(action.payload.dataList, "server");
            
            // console.log("user 2", userObject, serverObject);

            if(userObject && serverObject)
            {
                const jUser = JSON.parse(userObject);
                const jServer = JSON.parse(serverObject);

                return {...state, isSignedIn: true, user: jUser, server: jServer};
            }
        }
    }

    //if user was signed in earlier but is now signed out - update the state
    // - this part of the reduecer will act for all actions
    if(action.payload && action.payload.isSignedIn === 0 && (state.isSignedIn || state.isSignedIn == null))
    {
        return {...state, isSignedIn: false, user: null};
    }

    return state;
}

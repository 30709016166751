import React from 'react'
import SarasPopup from '../../../../library/container/popup/SarasPopup'
import SarasTrans from '../../../../library/notifications/SarasTrans'
import SarasDialog from '../../../../library/container/popup/SarasDialog3'

const DocSearchDynamicGroupPath = (props) => {
    const { targetRef, dynamicGroupKey, dynamicGroupData, onCancel } = props
    const [open, setOpen] = React.useState(true);

    const handleClose = (event) => {
        onCancel('');
        setOpen(false);
    }

    const renderDataValue = (row, index) =>{
        
        if(!row || !row.groupKey) return null;

        const groupLabel = dynamicGroupData[row.groupKey].dataList.label;

        return (
            <>
                <span style={{fontWeight:'bold', width: '15px', display:'inline-block'}}>{index + 1}</span>
                <span style={{fontWeight:'bold', width: '150px', display:'inline-block'}}>{groupLabel}</span>
                <span>{row.choiceLabel}</span>
            </>
        )
    }

    const renderData = () =>{
        if(dynamicGroupKey){
            return(
                <SarasDialog formStyle={{ maxWidth: '500px', maxHeight:'300px' }}
                contentStyle={{ height:'100px' }}
                title={<SarasTrans value="Deep Analysis Path" />}
                actionName='' cancelName=''>
                    <div className="uiBox">{
                        dynamicGroupKey.map((row, index)=>(
                            <div key={index}>
                                {renderDataValue(row, index)}
                            </div>
                        ))
                        }
                    </div>
                </SarasDialog>
            )
        }
        else{
            return (<span><SarasTrans value="Fetching Summary" />...</span>)
        }
    }

    return (
        <SarasPopup anchorRef={targetRef} open={open} onClose={handleClose}>
            {renderData()}
        </SarasPopup>
    )
}

export default DocSearchDynamicGroupPath


import InsightBarGraphView from './components/InsightBarGraphView'
import InsightColumnGraphView from './components/InsightColumnGraphView'
import InsightLineGraphView from './components/InsightLineGraphView'
import InsightPieGraphView from './components/InsightPieGraphView'
import InsightGeoGraphView from './components/InsightGeoGraphView'
import InsightStatView from './components/InsightStatView'

//Make sure this only contains insights related component
const colViewMap = {
    "vInsightViewBarGraph" : InsightBarGraphView,
    "vInsightViewColumnGraph" : InsightColumnGraphView,
    "vInsightViewLineGraph" : InsightLineGraphView,
    "vInsightViewPieGraph" : InsightPieGraphView,
    "vInsightViewGeoGraph" : InsightGeoGraphView,
    "vInsightViewStat" : InsightStatView
}

export default colViewMap;

import React,{useEffect,useRef} from 'react';
import 'date-fns';
import DateFnsUtils from '@date-io/date-fns';
import {MuiPickersUtilsProvider,KeyboardDatePicker} from '@material-ui/pickers';
import RootRef from '@material-ui/core/RootRef';

const SarasDatePicker = (props)=>{
    const {format, label, id, value, onChange, tabindex} = props;
    const [dateValue, setDateValue] = React.useState(null);
    const datePicker = useRef();

    const initialiseDPValue = () => {
      var valueFormated = value;
      
      if(!valueFormated) valueFormated = null;

      setDateValue(valueFormated);
    }
  
    useEffect(initialiseDPValue,[value]);

    const onDateChange = (date, value) => {
      // if(!date) return;
      setDateValue(date);
      if(onChange !== undefined) onChange(datePicker.current,value);
    }

    return(
      <RootRef rootRef={datePicker}>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <KeyboardDatePicker
          disableToolbar={false}
          variant="inline"
          format={format || "dd-MMM-yyyy"}
          margin="normal"
          id={id}
          label={label}
          value={dateValue}
          onChange={onDateChange}
          allowKeyboardControl={true}
          autoOk={true}
          className={`uiDatePicker ${props.className}`}
          KeyboardButtonProps={{
            'aria-label': 'change date',
          }}
          tabindex={tabindex}
        />
        </MuiPickersUtilsProvider>
        </RootRef>
    )
}

export default SarasDatePicker;
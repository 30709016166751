import React, {useEffect, useState} from 'react';
import useFormApi from '../docAutomation/useFormApi'
import SarasRadioButton from '../../../library/input/radioBox/SarasRadioButton1';
import SarasTrans from '../../../library/notifications/SarasTrans';
import ActionButton from '../../../library/input/buttons/button/SarasButton2';
import { checkOptionAvailable } from './insights_functions';
import SarasMenu from '../../../library/repeater/menu/SarasMenu';
import SarasMenuItem from '../../../library/repeater/menu/SarasMenuItem';
import { SarasMap } from '../../../library/decor/SarasIcons';
import InsightsCriteria from './InsightsCriteria';

const regionMenu = [
    {"world": "World"}, {"005": "South America"}, {"009": "Oceania"}, {"013": "Central America"}, {"021": "North America"},
    {"019": "Americas"}, {"029": "Caribbea"}, {"002": "All of Africa"}, {"017": "Central Africa"}, {"015": "Northern Africa"},
    {"018": "Southern Africa"}, {"150": "Europe"}, {"154": "Northern Europe"}, {"155": "Western Europe"}, {"039": "Southern Europe"},
    {"030": "Eastern Asia"}, {"034": "Southern Asia"}, {"035": "Asia/Pacific region"}, {"142": "Asia"}, {"143": "Central Asia"},
    {"145": "Middle East"}, {"151": "Northern Asia"}
]

const InsightsView = (props) => {
    const { 
        insightKey, viewData, viewMap, sycnhOption, onOptionChanged, sycnhReload, onReloadClicked,
        sycnhCriteria, onCriteriaChanged
        } = props;

    const [insightData, setInsightData] = useState(null);
    
    //string based criteria value - output from the criteria editor
    const [insightCriteriValue, setInsightCriteriaValue] = useState({key: null, value: null});

    //complete data required for criteria editor: list of parsed criteria, fileds list, conditions list
    const [insightCriteriaData, setInsightCriteriaData] = useState(null);

    const [insightOption, setInsightOption] = useState(null);
    const [insightViewKey, setInsightViewKey] = useState(null);
    const [insightRegionKey, setInsightRegionKey] = useState('world');
    const [isLoading, setIsLoading] = useState(false);
    const [showCriteriaEditor, setShowCriteriaEditor] = useState(false);
    const formApi = useFormApi();

    const synchCriteriaAction = () => {
        // console.log("layout response view data", "refresh criteria 1", sycnhCriteria, insightData, insightCriteriValue);

        //fire only if the current criteria is different from new criteria
        if(sycnhCriteria && insightData && sycnhCriteria.key !== null
            && sycnhCriteria.key === insightData.GroupKey
            && sycnhCriteria.value !== insightCriteriValue
        ) {
            // console.log("layout response view data", "refresh criteria 2", sycnhCriteria, insightData, insightCriteriValue);
            setInsightCriteriaValue(sycnhCriteria);
            onRefreshViewClick(null, sycnhCriteria);
        }
    };

    useEffect(synchCriteriaAction, [sycnhCriteria]);

    const synchReloadAction = () => {
        if(sycnhReload && !isLoading) {
            onRefreshViewClick();
        }
    };

    useEffect(synchReloadAction, [sycnhReload]);

    const synchOptionSelection = () => {
        if(sycnhOption && sycnhOption !== insightOption) {
            if(checkOptionAvailable(insightData.ViewData, sycnhOption)){
                setInsightOption(sycnhOption);
            }
        }
    };

    useEffect(synchOptionSelection, [sycnhOption]);

    const loadView = () => {
        if(!insightData) {
            let layoutAction = { actionId: "show", actionName: "appInsight", 
                actionParam: 
                {
                    'content-type':'insight-view',
                    'view-key': viewData.dataList.vk,
                    'view-criteria': insightCriteriValue.value
                },
                containerKey:""};
            formApi.runGenericAction(layoutAction, "appInsight", null, null, null, "", "", 0, onViewSuccess);
        }
    };

    useEffect(loadView, [insightData, insightViewKey]);

    const onViewSuccess = (action, containerKey, rowKey, headerKey, response) => {
        // console.log("layout response view data", response);

        setIsLoading(false);

        if(response.mainViewDataV5){
            const _insightViewData = JSON.parse(response.mainViewDataV5.viewData);
            const _insightViewKey = response.mainViewDataV5.viewKey;
            
            setInsightCriteriaData(response.mainViewData);
            setInsightData(_insightViewData);
            setInsightViewKey(_insightViewKey);
            setInsightOption(_insightViewData.DefaultOption);
        }
    }

    const onCriteriaEditorShow = () => {
        setShowCriteriaEditor(true);
    }

    const onCriteriaApply = (criteria) => {
        setShowCriteriaEditor(false);

        const newCriteria = {key: insightData.GroupKey, value: criteria};

        // console.log("layout response view data", newCriteria);
        setInsightCriteriaValue(newCriteria);

        //apply criteria
        onRefreshViewClick(null, newCriteria);

        onCriteriaChanged(newCriteria);
    }

    const onCriteriaClose = () => {
        setShowCriteriaEditor(false);
    }

    const onRefreshViewClick = (e, criteria) => {

        // console.log("layout response view data", "refresh criteria 3", criteria, insightCriteriValue);
        
        setIsLoading(true);

        let layoutAction = { actionId: "show", actionName: "appInsight", 
            actionParam: 
            {
                'content-type':'insight-view',
                'view-key': viewData.dataList.vk,
                'view-criteria': criteria === null || criteria === undefined ? insightCriteriValue.value : criteria.value
            }, 
            containerKey:""};

        formApi.runGenericAction(layoutAction, "appInsight", null, null, null, "", "", 0, onViewSuccess);

        if(onReloadClicked && !criteria) onReloadClicked();
    }

    const onDownloadViewClick = () => {

    }

    const onChangeOptionClick = (radioRef, value, isChecked) => {
        setInsightOption(value);

        if(onOptionChanged) onOptionChanged(value);
    }

    const onRegionChangeClick = (e, index) => {
        let regionKey = Object.keys(regionMenu[index])[0];

        setInsightRegionKey(regionKey);
    }

    // console.log("layout response view", viewData.dataList.vk);
    const renderDynamicView = () => {
        let DynamicComponent = viewMap[insightViewKey];

        if(!DynamicComponent)
        {
            DynamicComponent = viewMap["vInsightGraphView"];
        }

        if(!DynamicComponent)
        {
            return null;
        }
        else
        {
            return (
                <DynamicComponent viewData={insightData} activeOption={insightOption} mapRegionKey={insightRegionKey}/>
            )
        }
    }

    const renderActionView = () => {
        return (
            <div>
                <div className='uiInsightViewActionLeft'>
                {
                    Object.keys(insightData.ViewData).map((row, index) => (
                        <span key={index}>
                            <SarasRadioButton 
                                name="insight-option" value={row} checked={row === insightOption ? true: false} 
                                onChange={onChangeOptionClick}></SarasRadioButton>
                            <label className="uiLink" onClick={() => onChangeOptionClick(null, row, null)}><SarasTrans value={row} /></label>
                        </span>
                    ))
                }
                </div>
                <div className='uiInsightViewActionRight'>
                    {
                        insightData.EnableRegionFilter === 1 ?
                            <SarasMenu menuTrigger={<span className='uiAppIconWithLink'>
                                                        <SarasMap className='uiAppIcon'/>
                                                    </span>
                                                    } 
                                dataList={regionMenu} menuItem={SarasMenuItem} onSelection={onRegionChangeClick}></SarasMenu>
                        : null
                    }
                    {
                        insightData.EnableDownload === 1 ?
                            <ActionButton onClick={onDownloadViewClick} text="Download Data" icon="download" 
                                    tooltip="Download the insights data"
                                    className="uiHoverRound uiLink uiSpacerH5"/>
                        : null
                    }
                    {
                        insightData.EnableCriteriaEditor === 1 ?
                        <ActionButton onClick={onCriteriaEditorShow} text="Edit Criteria" icon="edit" 
                            tooltip="Edit the insights criteria"
                            className="uiHoverRound uiLink uiSpacerH5"/>
                            : null
                    }
                    <ActionButton onClick={onRefreshViewClick} text="Reload Data" 
                            tooltip="Refresh insights data"
                            icon="refresh" className={`${isLoading ? 'uiRotating' : ''} uiHoverRound uiLink uiSpacerH5`} />
                </div>
            </div>
        );
    }

    if(insightData)
    {
        if(showCriteriaEditor){
            return (
                <InsightsCriteria viewData={insightCriteriaData} 
                report={insightKey}
                onCriteriaApply={onCriteriaApply}
                onCriteriaClose={onCriteriaClose} />
            )
        }

        return (
            <div className='uiInsightView uiInsightBox'>
                {
                    insightData.EnableActionView === 1 ?
                        <div className='uiInsightViewAction'>
                            {renderActionView()}
                        </div>
                    : null
                }
                <div className={`${insightData.EnableActionView === 1 ? 'uiInsightViewContent' : 'uiInsightViewContentNoAction'}`}>
                    {renderDynamicView()}
                </div>
            </div>
        )
    }

    return null;
}

export default InsightsView;

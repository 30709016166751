import React from 'react'
import {FilledCancelIcon} from '../../../../library/decor/SarasIcons';
import SarasIconButton from '../../../../library/input/buttons/iconButton/SarasIconButton';
import SarasTrans from '../../../../library/notifications/SarasTrans'
import * as fn from "../../../../helper/uiFunctions";

const DocSearchFavouriteRow = (props) => {
    const { viewData, index, onChildAction } = props;

    const handleChildAction = (e) => {
        e.stopPropagation();
        // console.log("props in fav row click event", e.currentTarget.classList, e.target.classList, index);
        onChildAction(e, index);
    }

    const renderDelete = () => {
        if(!fn.compareNumber(viewData.dataList.isRO, 1)){
            return (
                <SarasIconButton color="#ef5350" className="uiMenuItemAction action-delete" 
                aria-label="delete favorite" onclick={handleChildAction} edge="edge">
                    <FilledCancelIcon 
                        style={{ fontSize: '18', color:'#ef5350'}} />
                </SarasIconButton>
            )
        }

        return null;
    }

    return (
        <>
            <span className="uiMenuItemLabel"><SarasTrans value = {`${viewData.dataList.cn}`}/></span>
            {renderDelete()}
        </>
    )
}

export default DocSearchFavouriteRow
import SarasErpApi from '../../../helper/SarasErpApi';

const acRequestData = async function (acDepctrl, acMethodGet, acParam, onSuccessCallBack) {

    let requestObject = {
        //to ensure modularity - it is possible that in future 
        //module specific controllers will handle its autocomplete
        //TODO hence give flexibility and pass an action name from variable
        //default is cmnAC
        actionName: 'cmnAC',
        //action type will be used by the server to route the request to an efficient route 
        //and generate an efficient response
        actionType: 'ac',
        actionId: acMethodGet,
        dataList: {
            'param': acParam || '*',
            ...acDepctrl
        }
    }

    // console.log("auto complete request object", requestObject);

    const response = await SarasErpApi.post('core', requestObject);
    
    if(response){
        if(onSuccessCallBack) onSuccessCallBack(response);
    }
}

export {acRequestData};

import React from 'react'

const DocSearchCriteriaRow = (props) => {
    const { viewData, rowKey, onCriteriaAction } = props;
    const anchorRef = React.useRef(null);

    const criteriaClicked = (e) => {
        let selRowKey = rowKey; //e.currentTarget.getAttribute("rowkey");
        let action = "action-select";

        if (e.target.classList.contains('action-delete')) {
            action = "action-delete";
        }

        // console.log("criteria click event", selRowKey, action);

        if(onCriteriaAction) onCriteriaAction(anchorRef, selRowKey, action);
    }

    return (
        <li ref={anchorRef} rowkey={rowKey} className="selected-criteria" name="criteria" onClick={criteriaClicked} >
            <span>{viewData.dataList.label}</span>
            <span className="uiDeleteActionCross action-delete"></span>
        </li>
    )
}

export default DocSearchCriteriaRow
import React,{useEffect} from 'react'
import DocSearchDynamicGroupRow from './DocSearchDynamicGroupRow'
import SarasMenu from '../../../../library/repeater/menu/SarasMenu'
import SarasTrans from '../../../../library/notifications/SarasTrans'
import DocSearchDynamicGroupChoice from './DocSearchDynamicGroupChoice'
import SarasGridItem from '../../../../library/container/grid/SarasGridItem'
import SarasGridContainer from '../../../../library/container/grid/SarasGridContainer'
import ActionButton from '../../../../library/input/buttons/button/SarasButton2';
import { buildDynamicGroupKeyValue, destructDynamicGroupKey } from './DocSCFunctions'
import DocSearchDynamicGroupPath from './DocSearchDynamicGroupPath'

const DocSearchDynamicGroup = (props) => {
    const { dynamicGroupData, dynamicGroupChoiceData, onGroupSelected, onSearchByDynamicGroup, currentKey } = props;

    //set multiple group key in following format
    //if selection is pending: sales_location none
    //if selection done: sales_locaiton locationId locationLabel,
    const [dynamicGroupKey, setDynamicGroupKey] = React.useState([{groupKey: '', choiceKey: '', choiceLabel: ''}]);
    const [activeGroupPosition, setActiveGroupPosition] = React.useState(0);
    const [popupName, setPopupName] = React.useState('');

    const anchorRef = React.useRef(null);

    const initialiseSelector = () => {
        //split the key and create an array from it
        //also update the active group position to the length of the array - 1
        if(currentKey){
            let keyArray = destructDynamicGroupKey(currentKey);

            setDynamicGroupKey(keyArray);
            setActiveGroupPosition(keyArray.length - 1);
        }
        else{
            setDynamicGroupKey([{groupKey: '', choiceKey: '', choiceLabel: ''}]);
            setActiveGroupPosition(0);
        }
    }

    //ensure state is updated whenever the props are changed by parent
    useEffect(initialiseSelector, [currentKey]);

    const onShowGroupPath = () => {
        setPopupName("groupPath");
    }

    const onHideGroupPath = () => {
        setPopupName("");
    }

    const onGotoPreviousGroup = () => {
        if(activeGroupPosition > 0){
            let newDynamicGroupKey = [ ...dynamicGroupKey ];

            //remove current group position
            delete newDynamicGroupKey[activeGroupPosition];
            
            //and make the previous one as current
            let newActiveGroupPosition = activeGroupPosition - 1;

            // console.log("dynamicgroup add group", newDynamicGroupKey, newActiveGroupPosition);
    
            setActiveGroupPosition(newActiveGroupPosition);
            setDynamicGroupKey([ ...newDynamicGroupKey ]);

            if (onSearchByDynamicGroup) onSearchByDynamicGroup(buildDynamicGroupKeyValue(newDynamicGroupKey));
        }
        else{
            let newDynamicGroupKey = [{groupKey: '', choiceKey: '', choiceLabel: ''}];
            setDynamicGroupKey([ ...newDynamicGroupKey ]);

            if (onSearchByDynamicGroup) onSearchByDynamicGroup(buildDynamicGroupKeyValue(newDynamicGroupKey));
        }
    }

    const onAddGroup = () => {
        let newActiveGroupPosition = activeGroupPosition + 1;

        let newDynamicGroupKey = [ ...dynamicGroupKey ];
        newDynamicGroupKey[newActiveGroupPosition] = {groupKey: '', choiceKey: '', choiceLabel: ''};
        
        // console.log("dynamicgroup add group", newDynamicGroupKey, newActiveGroupPosition);

        setActiveGroupPosition(newActiveGroupPosition);
        setDynamicGroupKey([ ...newDynamicGroupKey ]);
    }

    const onGroupSelect = (e, index) => {
        let groupRowClicked = dynamicGroupData[Object.keys(dynamicGroupData)[index]];
        if (!groupRowClicked) return;
        
        let newDynamicGroupKey = [ ...dynamicGroupKey ];
        newDynamicGroupKey[activeGroupPosition] = {groupKey: groupRowClicked.dataList.key, choiceKey: '', choiceLabel: ''};
        setDynamicGroupKey([ ...newDynamicGroupKey ]);

        if(onGroupSelected) onGroupSelected(buildDynamicGroupKeyValue(newDynamicGroupKey));
    }

    const onGroupChoiceChange = (key, val) => {
        let newDynamicGroupKey = [ ...dynamicGroupKey ];
        newDynamicGroupKey[activeGroupPosition] = {groupKey: dynamicGroupKey[activeGroupPosition].groupKey, choiceKey: key, choiceLabel: val};
        setDynamicGroupKey([ ...newDynamicGroupKey ]);

        // console.log("dynamicgroup keys", newDynamicGroupKey);

        if (onSearchByDynamicGroup) onSearchByDynamicGroup(buildDynamicGroupKeyValue(newDynamicGroupKey));
    }

    const renderGroups = () => {
        let group = null;

        if(dynamicGroupKey[activeGroupPosition].groupKey && dynamicGroupData){
            group = dynamicGroupData[dynamicGroupKey[activeGroupPosition].groupKey];
        }

        return (
            <>
                <span title="Select Field" className="uiActionWithMenu uiDynamicGroupSelector">
                    {group ? group.dataList.label : 'Select Field'}
                </span>
                <div title="Select Field" className="uiActionWithMenuSelector"></div>
            </>
        )
    }

    const renderGroupPath = () => {
        if (anchorRef == null) return null;

        if (popupName === "groupPath"){
            return (
                <>
                    <DocSearchDynamicGroupPath targetRef={anchorRef} dynamicGroupKey={dynamicGroupKey} 
                        dynamicGroupData={dynamicGroupData} onCancel={setPopupName}/>
                </>
            );
        }
    }

    const renderPreviousGroup = () => {
        if(activeGroupPosition > 0 || dynamicGroupKey[0].groupKey)
        {
            let choiceLabel = "";

            if(activeGroupPosition > 0)
            {
                choiceLabel = dynamicGroupKey[activeGroupPosition - 1].choiceLabel;
            }

            //show only part of the string to ensure screen 
            //is aligned correctly even for large strings
            if(choiceLabel.length > 20){
                choiceLabel = choiceLabel.substring(0, 20);
            }

            return (
                <span ref={anchorRef} onMouseEnter={onShowGroupPath} onMouseLeave={onHideGroupPath}>
                    <ActionButton ref={anchorRef} onClick={onGotoPreviousGroup} text="Go to previous Group" 
                            icon={activeGroupPosition === 0 ? "clear" : "backArrow"} 
                            className={`uiActionIconSimple uiHoverRound ${activeGroupPosition === 0 ? 'uiRed' : ''}`}/>
                    <span className='uiPreviousGroup'>{choiceLabel || 'Clear'}</span>
                    {renderGroupPath()}
                </span>
            );
        }
        else{
            return null;
        }
    }
    
    const renderAddGroup = () => {
        if(dynamicGroupKey[activeGroupPosition].choiceKey){
            return (
                <ActionButton containerClass="uiGroupButton" onClick={onAddGroup} text="Drill down by adding one more Group" icon="add" className="uiActionAddFav uiHoverRound"/>
            );
        }
        else{
            return (null);
        }
    }
    
    const renderDynamicGroupChoice = () => {
        if (dynamicGroupChoiceData && dynamicGroupKey[activeGroupPosition].groupKey) {
            return (
                <div>
                    <DocSearchDynamicGroupChoice 
                        previousChoiceKey={dynamicGroupKey[activeGroupPosition].choiceKey}
                        onGroupChoiceSelected={onGroupChoiceChange} 
                        viewData={dynamicGroupChoiceData.viewRows} />
                </div>
            )
        }
        else
            return null
    }
    
    return (
        <>
            <div className='uiDynamicGroupSection'>
                <SarasGridContainer direction="row" spacing={2}>
                    <SarasGridItem xs={4}>
                        <div>
                            {
                                renderPreviousGroup()
                            }
                            <span className='uiLblInLine'><SarasTrans value="Analyse By" /></span>
                            <SarasMenu menuContainerClass="uiInLine" menuTrigger={renderGroups()} 
                            dataList={Object.values(dynamicGroupData)} 
                            menuItem={DocSearchDynamicGroupRow} onSelection={onGroupSelect}></SarasMenu>
                            {
                                renderAddGroup()
                            }
                        </div>
                    </SarasGridItem>
                    <SarasGridItem xs={8}>
                        {
                            renderDynamicGroupChoice()
                        }
                    </SarasGridItem>
                </SarasGridContainer>
            </div>
        </>
)
}

export default DocSearchDynamicGroup
import React from 'react'
import SarasPopup from '../../../../library/container/popup/SarasPopup'
import SarasDialog from '../../../../library/container/popup/SarasDialog3'

const DocSearchSaveFavorite = (props) => {
    const { targetRef, addFav, onCancel } = props
    const [favName, setFavName] = React.useState('');
    const [open, setOpen] = React.useState(true);

    const actionFun = () => {
        addFav(favName)
        onCancel('')
    }

    const handleClose = (event) => {
        onCancel('')
        setOpen(false);
    };

    const cancelFun = () => {
        onCancel('')
    }

    const onNameChange = (e) => {
        setFavName(e.currentTarget.value)
    }

    return (
        <SarasPopup anchorRef={targetRef} open={open} onClose={handleClose}>
            <SarasDialog formStyle={{ width: '250px' }} title='Save favorite as..'
                actionName='Save' actionFunction={actionFun} cancelName='Close' cancelFunction={cancelFun} >
                <div id="popupenterCritname" className="uiConfirmDialogInput ui-enter-critname" >
                    <input type='text' id="cmnCriteriaName" maxLength="100" onChange={onNameChange} value={favName} />
                </div>
            </SarasDialog>
        </SarasPopup>
    )
}

export default DocSearchSaveFavorite

import React from 'react';

const UiDocument = (props) => {
    const {docName, suffix1, suffix2, children} = props;
    
    return(
        <div className={`grid-container-${suffix1}-${suffix2}`}>
            <div className={`ph${docName}Grid`}>
                { children }
            </div>
        </div>
    )
}

export default UiDocument
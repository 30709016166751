import React from 'react'

const DocSearchDynamicGroupRow = (props) => {
    const { viewData } = props;

    return (
        <>
            <span className="uiMenuItemLabel">{viewData.dataList.label}</span>
        </>
    )
}

export default DocSearchDynamicGroupRow
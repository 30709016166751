import React from "react";

const UiGridColOne = (props) => {
    const { children } = props
    return (
        <div className="uiGridCol">
            {children}
        </div>
    )
}

const UiGridColTwo = (props) => {
    const { children } = props
    return (
        <div className="uiGridColTwo">
            {children}
        </div>
    )
}

const UiGridColThree = (props) => {
    const { children } = props
    return (
        <div className="uiGridColThree">
            {children}
        </div>
    )
}

const UiGridColFour = (props) => {
    const { children } = props
    return (
        <div className="uiGridColFour">
            {children}
        </div>
    )
}

const UiGrid = (props) => {
    const { children } = props
    return (
        <div className="uiGrid">
            {children}
        </div>
    )
}

const UiRow = (props) => {
    const { children } = props
    return (
        <div className="uiRow">
            {children}
        </div>
    )
}

const UiCol = (props) => {
    const { id, children } = props
    return (
        <div id={id} className="uiCol">
            {children}
        </div>
    )
}

export {UiGridColOne, UiGridColTwo, UiGridColThree, UiGridColFour, UiGrid, UiRow, UiCol}
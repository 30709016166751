import React from 'react'
import SarasPopup from '../../../../library/container/popup/SarasPopup'
import SarasTrans from '../../../../library/notifications/SarasTrans'
import SarasDialog from '../../../../library/container/popup/SarasDialog3'

const DocSearchBirdEyeView = (props) => {
    const { targetRef, birdEyeViewData, isRow, onCancel, title } = props
    const [open, setOpen] = React.useState(true);

    const handleClose = (event) => {
        onCancel('');
        setOpen(false);
    }

    const renderDataValue = (row, rowValueKey) =>{
        var rowValue = row.rowValues[rowValueKey];

        return (
            <>
                <span style={{fontWeight:'bold', width: '190px', display:'inline-block'}}>{rowValue.dataList.label}</span>
                <span>{rowValue.valF || rowValue.val}</span>
            </>
        )
    }

    const renderData = () =>{
        if(birdEyeViewData && (isRow || (birdEyeViewData.ViewRowsKeys.length > 0))){
            const row = isRow ? birdEyeViewData : birdEyeViewData.viewRows[0];

            //TODO : Configure SarasTrans for BirdEyeView title for GroupBy as it is show all different titles as strings
            //Ex: Bird Eye View: Test Shop @ Phuket, Bird Eye View: Test Shop @ Phuket
            return(
                <SarasDialog formStyle={{ maxWidth: '300px', maxHeight:'300px' }}
                contentStyle={{ height:'100px' }}
                title={`Bird Eye View${title ? ': ' + title : ''}`}
                actionName='' cancelName=''>
                    <div className="uiBox" style={{margin:'0px'}}>{
                        row.RowValuesKeys.map((rowValueKey, index)=>(
                            <div key={index}>
                                {renderDataValue(row, rowValueKey)}
                            </div>
                        ))
                        }
                    </div>
                </SarasDialog>
            )
        }
        else{
            return (<span><SarasTrans value="Fetching Summary" />...</span>)
        }
    }

    return (
        <SarasPopup anchorRef={targetRef} open={open} onClose={handleClose}>
            {renderData()}
        </SarasPopup>
    )
}

export default DocSearchBirdEyeView

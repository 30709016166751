import React from 'react'

const DocSearchSortDirectionRow = (props) => {
    const { viewData } = props;

    return (
        <>
            <span className="uiMenuItemLabel">{viewData}</span>
        </>
    )
}

export default DocSearchSortDirectionRow
import React, { useRef } from 'react'
import Radio from '@material-ui/core/Radio';

const SarasRadioButton1 = (props)=>{
    const {id, value, checked, name, className, onChange} = props
    const radioBoxRef = useRef();

    const onSelectionChange = () => {
      const isChecked = radioBoxRef.current.checked;
        if (onChange) onChange(radioBoxRef, value, isChecked ? '1' : '0')
    }

    return(
        <Radio ref={radioBoxRef}
          id={id}
          classes={className}
          checked={checked}
          onChange={onSelectionChange}
          value={value}
          color="default"
          name={name}
          inputProps={{ 'aria-label': 'E' }}
          size="small"
        />
    )
}

export default SarasRadioButton1
import React,{useEffect} from 'react'
import DocSearchSortRow from './DocSearchSortRow'
import DocSearchSortDirectionRow from './DocSearchSortDirectionRow'
import SarasMenu from '../../../../library/repeater/menu/SarasMenu'
import SarasButton from '../../../../library/input/buttons/button/SarasButton2'

const sortDirections = {
    "asc": "A to Z",
    "desc": "Z to A"
}

const DocSearchSort = (props) => {
    const { viewData, onSortSelected, currentKey } = props;

    const [sortKey, setSortKey] = React.useState('');
    const [sortDirectionKey, setSortDirectionKey] = React.useState('asc');

    const initialiseSortSelector = () => {
        if(currentKey){
            let sortData = currentKey.split(' ');

            if(sortData.length === 2){
                setSortKey(sortData[0]);
                setSortDirectionKey(sortData[1]);
            }
            else{
                setSortKey('');
                setSortDirectionKey('asc');
            }
        }
        else{
            setSortKey('');
            setSortDirectionKey('asc');
        }
    }

    //ensure state is updated whenever the props are changed by parent
    useEffect(initialiseSortSelector, [viewData, currentKey]);

    const renderSortKeys = () => {
        return (
            <>
                <span title="Select Sort By" className="uiActionWithMenu">
                    {sortKey && viewData[sortKey] ? viewData[sortKey].dataList.label : 'Default Sort'}
                </span>
                <div title="Select Sort By Field" className="uiActionWithMenuSelector"></div>
            </>
        )
    }

    const renderSortDirections = () => {
        return (
            <>
                <span title="Select Sort Direction" className="uiActionWithMenu">
                    {sortDirections[sortDirectionKey]}
                </span>
                <div title="Select Sort Directions" className="uiActionWithMenuSelector"></div>
            </>
        )
    }

    const onSortSelect = (e, index) => {
        let sortRowClicked = viewData[Object.keys(viewData)[index]];
        if (!sortRowClicked) return;
        
        // console.log("fav row object", favRowClicked, favList);
        setSortKey(sortRowClicked.dataList.key);
    }

    const onDirectionSelect = (e, index) => {
        let sortDirectionClicked = Object.keys(sortDirections)[index];
        if (!sortDirectionClicked) return;
        
        // console.log("sort clicked", sortDirectionClicked, sortDirections[sortDirectionClicked]);
        setSortDirectionKey(sortDirectionClicked);
    }

    const onSubmitSort = () => {
        if(sortKey){
            let sortSel = sortKey + " " + sortDirectionKey;
            if(onSortSelected) onSortSelected(sortSel);
        }
    }

    // console.log("sorter", viewData);

    if(!viewData || Object.keys(viewData).length === 0)
        return(null)

    return (
        <>
            <SarasMenu menuTrigger={renderSortKeys()} 
            dataList={Object.values(viewData)} 
            menuItem={DocSearchSortRow} onSelection={onSortSelect}></SarasMenu>
            <SarasMenu menuTrigger={renderSortDirections()} 
            dataList={Object.values(sortDirections)} 
            menuItem={DocSearchSortDirectionRow} onSelection={onDirectionSelect}></SarasMenu>
            <SarasButton disabled={sortKey === ''} text="Change Sort" className="uiActionButton" onClick={onSubmitSort}/>
        </>
)
}

export default DocSearchSort
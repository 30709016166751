import React, { useEffect } from 'react'
import SarasTrans from '../../../../library/notifications/SarasTrans'
import SarasDatePicker from '../../../../library/input/datePicker/SarasDatePicker'
import SarasInput from '../../../../library/input/textField/SarasInput';
import SarasDD from '../../../../library/input/dropdown/SarasDD'
import SarasPopup from '../../../../library/container/popup/SarasPopup'
import SarasAC from '../../../..//library/input/autocomplete/SarasAutocomplete2'
import ActionButton from '../../../../library/input/buttons/button/SarasButton2';
import { getDefaultCriteriaByFieldType, buildFieldsKeyValueArray, buildCondKeyValueArray } from './DocSCFunctions'
import {convertStrToKeyValue} from '../../../../helper/uiFunctions';

const DocCriteriaEditor = (props) => {
    //targetRef is used for showing the popup under the desired div
    const { targetRef, criteriaObj, criteriaKey, fields, conditions, onUpdate, onCancel, onDelete } = props

    const fieldRef = React.useRef();
    const conditionRef = React.useRef();

    const [fieldDT, setFieldDT] = React.useState("");
    const [fieldList, setFieldList] = React.useState([]);
    const [conditionList, setConditionList] = React.useState([]);

    //store the selected field
    const [selField, setSelField] = React.useState("");
    //store the selected condition
    const [selCond, setSelCond] = React.useState("");
    //store the input value1
    const [selVal1, setSelVal1] = React.useState("");
    //store the input value2
    const [selVal2, setSelVal2] = React.useState("");

    const [selAcMethod, setSelAcMethod] = React.useState("");
    const [selAcDepCtrl, setSelAcDepCtrl] = React.useState("");

    //set to true when fieldDT is na
    const [showOnlyFields, setShowOnlyFields] = React.useState(false);
    //set to true when fieldDT is datetime
    const [showDP, setShowDP] = React.useState(false);
    //set to true when selected condition is a range
    const [showVal2, setShowVal2] = React.useState(false);

    //execute first time
    const initialiseFieldDT = () => {
        //field dt is not going to change so if it is already set then skip this method
        // if(fieldDT != "") return;

        //Shortcut search from the table criteria key will be passed
        //on clicking of criteria from search screen criteria object will be passed
        let tmpFieldDT = "";
        let defaultCondition = "";
        let defaultField = "";
        let tmpACMethod = "";
        let tmpAcDepCtrl = "";

        if (criteriaObj !== undefined && criteriaObj != null) {
            tmpFieldDT = criteriaObj.dataList.fieldDT;
            defaultCondition = criteriaObj.dataList.conditionKey;
            defaultField = criteriaObj.dataList.fieldKey;
            setSelVal1(criteriaObj.dataList.criteria1);
            setSelVal2(criteriaObj.dataList.criteria2);
            tmpACMethod = criteriaObj.dataList.acMethod;
            tmpAcDepCtrl = criteriaObj.dataList.acDepCtrl;

            // console.log("criteria", criteriaObj);
        }
        else if (criteriaKey !== undefined && criteriaKey != null) {
            //get the field object from the fields by using criteria key
            let tmpField = fields[criteriaKey];
            if (tmpField !== undefined) {
                tmpFieldDT = tmpField.dataList.fieldDT;
                tmpACMethod = tmpField.dataList.acMethod;
                tmpAcDepCtrl = tmpField.dataList.acDepCtrl;
                defaultField = criteriaKey;
            }
        }

        //default to string
        if (tmpFieldDT === "") tmpFieldDT = "string";
        if (defaultCondition === "") defaultCondition = getDefaultCriteriaByFieldType(tmpFieldDT);

        // console.log("initial values", defaultField, tmpFieldDT, defaultCondition);
        //set only if the value changes
        if (defaultField !== selField) setSelField(defaultField);
        if (tmpFieldDT !== fieldDT) setFieldDT(tmpFieldDT);
        if (defaultCondition !== selCond) setSelCond(defaultCondition);

        let depAction;

        tmpAcDepCtrl = tmpAcDepCtrl || '';

        if(tmpAcDepCtrl.indexOf(":") > 0)
        {
            depAction = convertStrToKeyValue(tmpAcDepCtrl, ",", ":");
        }
        else{
            depAction = {"dparam":tmpAcDepCtrl}
        }

        setSelAcMethod(tmpACMethod);
        setSelAcDepCtrl({...depAction, reqPerm: 1});
    }

    //execute whenever target changes
    useEffect(initialiseFieldDT, [targetRef]);

    //execute whenever field dt changes - effectively this also will run only once
    const initialiseOnFieldDTChange = () => {
        //get the fields list key value pair for the given field dt
        let fieldsKeyVal = buildFieldsKeyValueArray(fieldDT, fields);
        setFieldList(fieldsKeyVal);

        //get the condition list key value pair for the given field dt
        setConditionList(buildCondKeyValueArray(fieldDT, conditions));

        //set default field if it is not set
        if (selField === "" && fieldsKeyVal.length > 0) setSelField(fieldsKeyVal[0].key);

        //set to true when fieldDT is na
        setShowOnlyFields((fieldDT === "na"));
        //set to true when fieldDT is datetime
        setShowDP((fieldDT === "datetime"));
    }

    //execute whenever field dt changes - effectively this also will run only once
    useEffect(initialiseOnFieldDTChange, [fieldDT]);

    //execute whenever condition changes
    const initialiseOnCondChange = () => {
        // console.log("condition use effect fired", fieldDT, showDP);
        if (selCond != null && selCond.length > 0) {
            if (selCond.toLowerCase() === "range") {
                setShowVal2(true);
            }
            else {
                setSelVal2("");
                setShowVal2(false);
            }
        }
        else {
            setShowVal2(false);
        }
    }

    useEffect(initialiseOnCondChange, [selCond]);

    const onUpdateClick = (e) => {
        // console.log("criteria on update", selField, selCond, selVal1, selVal2);
        if (onUpdate) onUpdate(criteriaObj, selField, selCond, selVal1, selVal2);
    }

    const onCancelClick = (e) => {
        if (onCancel) onCancel();
    }

    const onDeleteClick = (e) => {
        if(onDelete && criteriaObj) onDelete(criteriaObj.rowKey, true);
    }

    const fieldSelectionChanged = (e, value) => {
        //field dt is not going to change so if it is already set then skip this method
        // if(fieldDT != "") return;

        //Shortcut search from the table criteria key will be passed
        //on clicking of criteria from search screen criteria object will be passed
        let tmpFieldDT = "";
        let defaultCondition = "";
        let tmpACMethod = "";
        let tmpAcDepCtrl = "";

        //get the field object from the fields by using criteria key
        let tmpField = fields[value];

        if (tmpField !== undefined) {
            tmpFieldDT = tmpField.dataList.fieldDT;
            tmpACMethod = tmpField.dataList.acMethod;
            tmpAcDepCtrl = tmpField.dataList.acDepCtrl;
        }

        //default to string
        if (tmpFieldDT === "") tmpFieldDT = "string";
        if (defaultCondition === "") defaultCondition = getDefaultCriteriaByFieldType(tmpFieldDT);

        //set only if the value changes
        setSelField(value);
        if (tmpFieldDT !== fieldDT) setFieldDT(tmpFieldDT);
        if (defaultCondition !== selCond) setSelCond(defaultCondition);

        let depAction;

        tmpAcDepCtrl = tmpAcDepCtrl || '';

        if(tmpAcDepCtrl.indexOf(":") > 0)
        {
            depAction = convertStrToKeyValue(tmpAcDepCtrl, ",", ":");
        }
        else{
            depAction = {"dparam":tmpAcDepCtrl}
        }

        setSelAcMethod(tmpACMethod);
        setSelAcDepCtrl({...depAction, reqPerm: 1});
    }

    const condSelectionChanged = (e, value) => {
        // console.log("new value for cond", value);
        setSelCond(value);
    }

    const value1Changed = (e) => {
        setSelVal1(e.currentTarget.value);
    }

    const acValue1Changed = (e, value) => {
        console.log("acmethod", value);

        if(fieldDT === "string"){
            setSelVal1(value.text);
        }
        else{
            setSelVal1(value.key);
        }
    }

    const dpValue1Changed = (dp, value) => {
        setSelVal1(value);
    }

    const value2Changed = (e) => {
        setSelVal2(e.currentTarget.value);
    }

    const dpValue2Changed = (dp, value) => {
        setSelVal2(value);
    }

    const renderConditions = () => {
        if (showOnlyFields) {
            return null;
        }
        else {
            return (
                <li>
                    <span className="uiLbl"><SarasTrans value='Criteria' /></span>
                    <SarasDD ctrlRef={conditionRef} ctrlID="critEditorConds" dataList={conditionList}
                        currentValue={selCond} onChange={condSelectionChanged} />
                </li>
            )
        }
    }

    const renderValue1 = () => {
        // console.log("render value 1 fired", showOnlyFields, showDP);
        if (showOnlyFields) {
            return null;
        }
        else if (selAcMethod){
            // console.log("acmethod", selAcMethod, selAcDepCtrl);

            return (
                <li>
                    <span className="uiLbl"><SarasTrans value='Value' /> 1</span>
                    <SarasAC autofocus acValidate="0" 
                        onChange={acValue1Changed} value={selVal1} label={selVal1}
                        acMethod={selAcMethod}
                        acDepCtrl={selAcDepCtrl}
                    />
                </li>
            )
        }
        else {
            return (
                <li>
                    <span className="uiLbl"><SarasTrans value='Value' /> 1</span>
                    <SarasInput autofocus value={selVal1} onSearchAsKeyed={value1Changed} />
                    {
                        showDP ? (
                            <SarasDatePicker id='critEditorDP1' className='uiHideMuiDatepickerTxt' onChange={dpValue1Changed} />
                        ) : ''
                    }
                </li>
            )
        }
    }

    const renderValue2 = () => {
        // console.log("render value 2 fired", showOnlyFields, showDP);
        if (showOnlyFields || !showVal2) {
            return null;
        }
        else if (selAcMethod){
            // console.log("acmethod", selAcMethod, selAcDepCtrl);

            return (
                <li>
                    <span className="uiLbl"><SarasTrans value='Value' /> 1</span>
                    <SarasAC autofocus acValidate="0" 
                        onChange={acValue1Changed} value={selVal1} label={selVal1}
                        acMethod={selAcMethod}
                        acDepCtrl={selAcDepCtrl}
                    />
                </li>
            )
        }
        else {
            return (
                <li>
                    <span className="uiLbl"><SarasTrans value='Value' /> 2</span>
                    <SarasInput value={selVal2} onSearchAsKeyed={value2Changed} />
                    {
                        showDP ? (
                            <SarasDatePicker id='critEditorDP2' className='uiHideMuiDatepickerTxt' style={{ width: '50px' }} onChange={dpValue2Changed} />
                        ) : ''
                    }
                </li>
            )
        }
    }

    return (
        <SarasPopup anchorRef={targetRef} open={true} onClose={onCancelClick}>
            <div className="uiCriteriaUpdater" id="srcBldrCriteriaUpdater">
                <ul>
                    <li>
                        <span className="uiLbl"><SarasTrans value='Field' /></span>
                        <SarasDD ctrlRef={fieldRef} ctrlID="critEditorFields" dataList={fieldList}
                            currentValue={selField} onChange={fieldSelectionChanged} />
                    </li>
                    {renderConditions()}
                    {renderValue1()}
                    {renderValue2()}
                </ul>
                <div className="phViewAction uiDialog uiSpacer10">
                    <div className="uiDialogAction" style={{ clear: "both", height:"30px" }}>
                        <ActionButton onClick={onDeleteClick} icon={'delete'} tooltip='Delete' 
                            ksc="ctrl+d"
                            className="uiActionIconRound uiColorNegativeAction"
                            containerStyle={{left:'10px', position:'absolute'}}/>
                        <ActionButton onClick={onUpdateClick} icon={'confirm'} tooltip='Apply Filter' 
                            ksc="enter"
                            className="uiActionIconRound uiColorActionGreen"
                            containerStyle={{right:'40px', position:'absolute'}}/>
                        <ActionButton onClick={onCancelClick} icon={'cancel'} tooltip='Cancel Changes' 
                            ksc="esc"
                            className="uiActionIconRound uiColorActionGrey"
                            containerStyle={{right:'10px', position:'absolute'}}/>
                    </div>
                </div>
            </div>
        </SarasPopup>
    )
}

export default DocCriteriaEditor
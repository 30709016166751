import React from "react";

const UiGroupContent = (props) => {
    const { children } = props
    return (
        <div className={props.className || "uiGroupContent"}>
            {children}
        </div>
    )
}

const UiGroupHeader = (props) => {
    const { children } = props
    return (
        <div className="uiGroupHeader">
            {children}
        </div>
    )
}

export {UiGroupHeader, UiGroupContent}
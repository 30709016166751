import React, {useEffect, useState} from 'react';
import { useSelector } from 'react-redux';
import useFormApi from '../docAutomation/useFormApi'
import GridMenu from '../../../layout/main/components/GridMenu';
import SarasMenu from '../../../library/repeater/menu/SarasMenu';
import InsightLayoutRow from './InsightLayoutRow';
import SarasTrans from '../../../library/notifications/SarasTrans';
import { DrowDownArrow, InfiniteLoop } from '../../../library/decor/SarasIcons';
import { buildLayoutConfig } from './insights_functions';
import InsightsView from './InsightsView';
import insightViewMap from './Index'
import SarasDynamicGrid from '../../../library/container/grid/SarasDynamicGrid';

const Insights = () => {
    const [currentLayoutKey, setCurrentLayoutKey] = useState("");
    const [currentLayoutConfig, setCurrentLayoutConfig] = useState(null);
    const [layoutList, setLayoutList] = useState(null);
    const [currentLayout, setCurrentLayout] = useState(null);
    const [sycnhOption, setSycnhOption] = useState('');
    const [sycnhReload, setSycnhReload] = useState(0);
    const [sycnhCriteria, setSycnhCriteria] = useState({key: null, value: null});

    const formApi = useFormApi();

    const serverAttributes = useSelector(state => state.auth.server);

    const viewMap = {...insightViewMap};

    const loadLayout = () => {
        if(serverAttributes.InsightsActive === 1 && !layoutList && currentLayoutKey !== "-1") {
            let layoutAction = { actionId: "get", actionName: "appInsight", actionParam: {'content-type':'insight-layout-current'}, containerKey:""};
            formApi.runGenericAction(layoutAction, "appInsight", null, null, null, "", "", 0, onLayoutSuccess);
        }
    };

    useEffect(loadLayout, [serverAttributes, layoutList, currentLayoutKey]);

    const onLayoutSuccess = (action, containerKey, rowKey, headerKey, response) => {
        // console.log("layout response", response);

        //Get layout list
        //Get current layout
    
        if(response){
            setCurrentLayoutKey(response.mainViewData.headerRows.config.dataList.currentLayoutKey);

            if(response.mainViewData.headerRows.config.dataList.currentLayoutKey !== "-1")
            {
                setLayoutList(response.mainViewData.childViews.layoutListView);
                setCurrentLayout(response.mainViewData.childViews.layoutView);

                //read the layout config and set it in the state
                setCurrentLayoutConfig(buildLayoutConfig(response.mainViewData.childViews.layoutView.headerRows.config.dataList.currentLayoutConfig));
            }
        }
    }
    
    const onLayoutSelect = (e, index) => {
        let layoutRowClicked = layoutList.viewRows[Object.keys(layoutList.viewRows)[index]];
        if (!layoutRowClicked) return;
        
        // console.log("fav row object", favRowClicked, favList);
        setCurrentLayoutKey(layoutRowClicked.dataList.lk);

        //fire api request to load new layout
        let layoutAction = { actionId: "get", actionName: "appInsight", actionParam: {
            'content-type':'insight-layout-switch',
            'insight-layout-key': layoutRowClicked.dataList.lk
        }, containerKey: ""};
        formApi.runGenericAction(layoutAction, "appInsight", null, null, null, "", "", 0, onLayoutSuccess);
    }

    const onChangeOptionClick = (value) => {
        setSycnhOption(value);
    }

    const onReloadClick = () => {
        setSycnhReload(sycnhReload + 1);
    }

    const onCriteriaChange = (newCriteria) => {
        setSycnhCriteria(newCriteria);
    }

    const renderLayoutKeys = () => {
        return (
            <div className='uiInsightActionContainer'>
                <span title="Select Insight Dashboard To View" className="uiInsightLayoutIcon">
                    <InfiniteLoop style={{margin:'0px',color:'#fff'}}/>
                </span>
                <span title="Select Insight Dashboard To View" className="uiActionWithMenu uiInsightLayoutMenu">
                    {currentLayoutKey && layoutList.viewRows[currentLayoutKey] ? layoutList.viewRows[currentLayoutKey].dataList.ln : 'Default Layout'}
                </span>
                <span title="Select Insight Dashboard To View" className="uiInsightLayoutSelector">
                    <DrowDownArrow style={{margin:'0px',color:'#fff'}}/>
                </span>
            </div>
        )
    }

    // console.log ("server attributes", serverAttributes);

    //server attributes are still not available, hence render nothing and wait for it
    if(! serverAttributes || serverAttributes.AppVersion === "") return null;

    
    if(serverAttributes.InsightsActive === 1)
    {
        // insights are needed but not yet loaded, so render nothing and wait for it
        if(!layoutList || !currentLayoutConfig) return null;

        return (
            <div className='uiInsightContainer'>
                <div className='uiInsightList uiInsightBox'>
                    <div className='uiInsightTitle'>
                        <span><SarasTrans value="Insights Dashboard" /></span>
                    </div>
                    <div className='uiInsightAction'>
                        <SarasMenu menuTrigger={renderLayoutKeys()} 
                            dataList={Object.values(layoutList.viewRows)} 
                            menuItem={InsightLayoutRow} onSelection={onLayoutSelect}></SarasMenu>
                    </div>
                </div>
                <div className='uiInsightLayout'>
                    <SarasDynamicGrid layoutConfig={currentLayoutConfig}>
                        {
                            Object.values(currentLayout.viewRows).map((row, index)=>(
                                <div key={row.dataList.vk}>
                                    <InsightsView viewData={row} key={index} viewMap={viewMap} 
                                        insightKey={row.dataList.vk}
                                        sycnhOption={sycnhOption} 
                                        onOptionChanged={onChangeOptionClick} 
                                        sycnhReload={sycnhReload} 
                                        onReloadClicked={onReloadClick} 
                                        sycnhCriteria={sycnhCriteria}
                                        onCriteriaChanged={onCriteriaChange}
                                    />
                                </div>
                            ))
                        }
                    </SarasDynamicGrid>
                </div>
            </div>
        );
    }
    else
    {
        return (
            <GridMenu></GridMenu>
        )
    }
}

export default Insights;

import React from 'react';

const UiForm = (props) => {
    const {formName, children} = props
    return(
            <div className={`phSection${formName}`}>
                { children }
            </div>
    )
}

export default UiForm
import SarasErpApi from '../../../../helper/SarasErpApi';
import * as fn from "../../../../helper/uiFunctions";
import {isNumeric, isValidDate, getTicks, multiLingualEncoding, multiLingualDecoding} from '../../../../helper/uiFunctions';

// var srcBldrCriteriaRange = "Range";
var srcBldrDefaultCriteriaRest = "=";
var srcBldrDefaultCriteriaString = "Contains";
var srcBldrDefaultCriteriaScan = "=";
var srcBldrNADefaultValue = "Yes";

let srcDoctypeId;


const getSearchParamDataType = function (searhParam) {
    var value = searhParam;
    var fieldDT = "string,scan";

    if (isNumeric(value)) {
        fieldDT = "int";
    }
    else if (value === "true" || value === "false" || value === "yes" || value === "no") {
        fieldDT = "bool";
    }
    else if (isValidDate(value)) {
        fieldDT = "datetime";
    }
    else if( value.trim() === "") {
        fieldDT = "na";
    }
    else {
        fieldDT = "string,scan";
    }

    // console.log("search param data type", fieldDT);

    //get the fields matching the data type
    return fieldDT;
}

const getDefaultCriteriaByFieldType = function (fieldDT) {
    if (fieldDT) {
        if (fieldDT === "string") {
            return srcBldrDefaultCriteriaString;
        }
        else if (fieldDT === "scan") {
            return srcBldrDefaultCriteriaScan;
        }

        return srcBldrDefaultCriteriaRest;
    }

    return undefined;
}

const getSearchFieldsMatchingParam = function(searhParam, fields) {
    return getSearchFieldSourceByDataType(getSearchParamDataType(searhParam), fields);
}

const getSearchFieldSourceByDataType = function (fieldDT, fields) {
    // console.log("available fields", fields);

    let filteredFields = [];

    for (var i = 0; i < fields.length; i++) {
        if (fieldDT.indexOf(fields[i].dataList.fieldDT) > -1) {
            filteredFields.push(fields[i]);
        }
    }

    // console.log("filtered fields", filteredFields);

    return filteredFields;
}

const encodeSystemCharacter = function (value) {
    if (value && value.length > 0) {
        value = value.replace(/:/g, '#COLON#');
        value = value.replace(/,/g, '#COMMA#');
    }

    return value;
}

const decodeSystemCharacter = function (value) {
    if (value && value.length > 0) {
        value = value.replace(/#COLON#/g, ':');
        value = value.replace(/#COMMA#/g, ',');
    }

    return value;
}

const buildSearchCriteria = function(criObj, fieldKey, condKey, val1, val2, fields, criteriaIndex) {
    //build criteria according to the same format as used by the server

    if(!fields) return;
    
    //get the field from field key
    let field = fields[fieldKey];

    if(!field) return;

    // console.log("selected field", field);
    
    //get the key for the new row
    let newRowKey = 0;
    
    //new object
    if(criObj == null){
        newRowKey = getTicks() + (criteriaIndex || 1);
    }
    else{
        //existing object
        newRowKey = criObj.rowKey;
    }

    // console.log("row key", newRowKey);
    
    if(!val1) val1 = "";
    if(!val2) val2 = "";

    if(val1.trim() === "") val1 = "";
    if(val2.trim() === "") val2 = "";

    //get the default condition
    if(!condKey){
        condKey = getDefaultCriteriaByFieldType(field.dataList.fieldDT);
    }

    if(field.dataList.fieldDT === "na" && !val1){
        val1 = srcBldrNADefaultValue;
    }

    val1 = encodeSystemCharacter(val1);
    val2 = encodeSystemCharacter(val2);

    let val2Label = "";
    let val2Val = "";

    if(val2 !== "") {
        val2Label = ":" + decodeSystemCharacter(val2);
        val2Val = ":" + multiLingualEncoding(val2);
    }
    
    //create the json criteria with encoded search paaram to take care of space within value provided by user
    let newCriteria = {
        "rId": newRowKey,
        "rowKey": newRowKey,
        "dataList": {
            "conditionKey": condKey,
            "criteria1": val1,
            "criteria2": val2,
            "fieldDT": field.dataList.fieldDT,
            "acMethod": field.dataList.acMethod,
            "acDepCtrl": field.dataList.acDepCtrl,
            "fieldKey": fieldKey,
            "label": field.dataList.label + " " + condKey + " " + decodeSystemCharacter(val1) + val2Label,
            "val": fieldKey + " " + condKey + " " + multiLingualEncoding(val1) + val2Val
        }
    }

    // console.log("new criteria ", newCriteria);

    return newCriteria;
}

const destructDynamicGroupKey = function(dynamicGroupKey){
    // console.log("search", criteriaVal);
    let groupList = [];
    let groups = dynamicGroupKey.split('_$$_');
    let cIndex = 0;

    for (const group of groups) {
        // console.log("search", criteria);
        let groupCondition = group.split(' ');

        let val1 = "";
        let val2 = "";

        if(groupCondition.length === 3){
            val1 = groupCondition[1];
            val2 = multiLingualDecoding(groupCondition[2]);
        }

        groupList[cIndex] = {groupKey: groupCondition[0], choiceKey: val1, choiceLabel: val2};

        cIndex++;
    }
    
    return groupList;
}

const buildDynamicGroupKeyValue = function(groupList){
    if(!groupList) return "";

    let glValue = "";
    let toReturn = [];
    let glValues = Object.values(groupList);

    for (let c = 0; c < glValues.length; c++) {
        let val =  (glValues[c].groupKey + ' ' + glValues[c].choiceKey + ' ' + multiLingualEncoding(glValues[c].choiceLabel)).trim();

        toReturn.push(val);
        //no need to encode the entire string, only encode the search param while creating the list
        // toReturn.push(encodeURIComponent(val));
    }

    glValue = toReturn.join("_$$_");

    // console.log("dynamicgroup key as string", glValue);

    return glValue;
}

const buildCriteriaValueFromJsonList = function(scList){
    if(!scList) return "";

    // console.log("search list", scList);

    let criteriaValue = "";
    let toReturn = [];
    let scListValues = Object.values(scList);

    for (let c = 0; c < scListValues.length; c++) {
        let val = scListValues[c].dataList.val;
        
        // console.log("search value ", val);

        toReturn.push(val);
        //no need to encode the entire string, only encode the search param while creating the list
        // toReturn.push(encodeURIComponent(val));
    }

    criteriaValue = toReturn.join(",");

    // console.log("criteria as string", criteriaValue);

    return criteriaValue;
}

const buildSearchCriteriaFromString = function (criteriaVal, fields) {
    
    // console.log("search", criteriaVal);

    let newSCList = {};
    let favCriteriaVal = criteriaVal.split(',');
    let cIndex = 0;

    for (const criteria of favCriteriaVal) {
        cIndex++;
        console.log("search", criteria);
        let splitCriteria = criteria.split(' ');
        let values = splitCriteria[2].split(':');
        let val1 = decodeURI(values[0]);
        let val2 = values[1]?decodeURI(values[1]):'';
        let newCriteria = buildSearchCriteria(null, splitCriteria[0], splitCriteria[1], val1, val2, fields, cIndex);
        
        newSCList[newCriteria.rowKey] = newCriteria;
    }
    
    return newSCList;
}

const buildGroupsKeyValueArray = function(groups){
    let result = {list:[], defaultKey: ''};
    
    if(!groups) return result;

    let groupsArray = Object.values(groups);

    for (var i = 0; i < groupsArray.length; i++) {
        if(!fn.compareNumber(groupsArray[i].dataList.isHid, 1)){
            if(fn.compareNumber(groupsArray[i].dataList.isDefault, 1)){
                if(groupsArray[i].rowKey)
                {
                    result.defaultKey = groupsArray[i].rowKey;
                }
                else
                {
                    result.defaultKey = groupsArray[i].key;
                }
            }
    
            result.list.push({key: groupsArray[i].rowKey || groupsArray[i].key, val: groupsArray[i].dataList.label, cssStyle: groupsArray[i].dataList.cssStyle});
        }
    }

    // console.log("filtered fields", result);

    return result;
}

const buildGroupsChoiceKeyValueArray = function(groups){
    let result = {list:[], defaultKey: ''};
    
    if(!groups) return result;

    let groupsArray = Object.values(groups);

    for (var i = 0; i < groupsArray.length; i++) {
        if(!fn.compareNumber(groupsArray[i].dataList.isHid, 1)){
            if(fn.compareNumber(groupsArray[i].dataList.isDefault, 1)){
                result.defaultKey = groupsArray[i].dataList.key;
            }
    
            result.list.push({rowKey:groupsArray[i].rowKey, key: groupsArray[i].dataList.key, val: groupsArray[i].dataList.label});
        }
    }

    // console.log("filtered fields", result);

    return result;
}

const buildFieldsKeyValueArray = function(fieldDT, fields){
    if(!fieldDT || fieldDT.length === 0 || !fields) return [];
    let result = [];
    let fieldsArray = Object.values(fields);

    for (var i = 0; i < fieldsArray.length; i++) {
        if (fieldsArray[i].dataList.fieldDT === fieldDT) {
            result.push({key: fieldsArray[i].rowKey, val: fieldsArray[i].dataList.label});
        }
    }

    // console.log("filtered fields", result);

    return result;
}

const buildCondKeyValueArray = function(fieldDT, conds){
    if(!fieldDT || fieldDT.length === 0 || !conds) return [];
    let result = [];
    let condArray = Object.values(conds[fieldDT].dataList);

    // console.log("condition array to key val pair", condArray);

    // return result;
    for (var i = 0; i < condArray.length; i++) {
        result.push({key: condArray[i], val: condArray[i]});
    }

    // console.log("filtered fields", result);

    return result;
}

const searchRecords = async function (actionType, pageNo, doctypeId, urlParams, favKey, criteriaValue, groupKey, sortKey, dynamicGroupKey, reload, subViewID, searchStateKey, onSuccessCallBack) {
    var action = "search";
    if(!favKey) favKey = "0";
    if(!criteriaValue) criteriaValue = "";
    if(!groupKey) groupKey = "";
    if(!sortKey) sortKey = "";
    if(!dynamicGroupKey) dynamicGroupKey = "";
    if(!actionType) actionType = "detailView";
    
    if(reload !== undefined) reload = 1;
    else reload = 0;

    var params = {"dtId": doctypeId, "pId": pageNo, "groupKey": groupKey, 
    "sortKey" : sortKey, "dynamicGroupKey": dynamicGroupKey, "criteriakey": favKey, 
    "cmnCriteriaValue": criteriaValue, "searchStateKey": searchStateKey};

    //TODO function to split the action param with & and convert it into object
    // if(srcSearchActionParam != undefined)
    // {
    //     params = params + "&" + srcSearchActionParam;
    // }

    let requestObject = {
        actionName: action,
        actionId: 'search',
        actionType: actionType,
        sview: subViewID,
        viewDataReload: reload,
        viewData: {
            masterKeys: {
                dtId: doctypeId
            },
            paging: {
                currentPage: pageNo
            }
        },
        dataList: {
            ...urlParams,
            ...params
        }
    }

    // console.log('search fired', requestObject);
    const response = await SarasErpApi.post('core', requestObject);
    // console.log('search response received', response.data.mainViewData);
    if(onSuccessCallBack) onSuccessCallBack(response);

    // srcShowSelectors = hasSelector;
}

const restoreSearchState = async function (doctypeId, urlParams, onSuccessCallBack, subViewID, initialeConfig) {
    var action = "search";
    var params = {"restorecriteria" :1 , "dtId": doctypeId};

    let requestObject = {
        actionName: action,
        actionId: 'search',
        sview: subViewID,
        viewData: {
            masterKeys: {
                dtId: doctypeId
            },
            paging: {
                currentPage: 1
            }
        },
        dataList: {
            ...urlParams,
            ...params,
            ...initialeConfig
        }
    }

    // console.log('search restore fired', requestObject);
    const response = await SarasErpApi.post('core', requestObject);
    // console.log('search restore response received', response.data.mainViewData);
    if(onSuccessCallBack) onSuccessCallBack(response);

    // srcShowSelectors = hasSelector;
}


/*favourite */

const saveFavCriteria = async function (criteria,favName,doctypeId,groupKey,onSuccessCallBack, rptKey, actionName) {
    var action = actionName || 'cmnsearch';

    var params = {'actname': action , "actid":"search", "savefav":1, "acttype":"save", "cmnCriteriaValue": criteria, "cmnCriteriaName": favName}
    params = {...params, ...get_default_SearchFav_param(undefined)}

    if (rptKey !== undefined) {
        params.rptKey = rptKey;
    }
    if(groupKey)
        params.groupKey = groupKey

    //sarasFormFunctions.saModule.sendRequest(params, action, "cmnSearchFavlist", "", "", "Save_Fav_Criteria_success", undefined);
    let requestObject = {
        actionName: actionName || 'search',
        actionId: 'search',
        viewData: {
            masterKeys: {
                dtId: doctypeId
            }
        },
        dataList: {
            ...params
        }
    }
    const response = await SarasErpApi.post('core', requestObject);
    //console.log('tytyty',requestObject)
    if(onSuccessCallBack) onSuccessCallBack(response);
}

const deleteFavourite = async function (criteriakey,doctypeId,groupKey,onSuccessCallBack, rptKey, actionName) {
    var action = actionName || 'cmnsearch';
    var params = {"actname": action , "actid":'search',"acttype":'save','delfav':1 } 
    params = {...params, ...get_default_SearchFav_param(criteriakey)}

    if (rptKey !== undefined) {
        params.rptKey = rptKey;
    }
    
    if(groupKey)
        params.groupKey = groupKey

    //sarasFormFunctions.saModule.sendRequest(params, action, "cmnSearchFavlist", "", "", "Delete_Icon_Control", undefined);
    let requestObject = {
        actionName: actionName || 'search',
        actionId: 'search',
        viewData: {
            masterKeys: {
                dtId: doctypeId
            }
        },
        dataList: {
            ...params
        }
    }

    const response = await SarasErpApi.post('core', requestObject);

    if(onSuccessCallBack) onSuccessCallBack(response)
}

const get_default_SearchFav_param = function (criteriakey) {

    var params = {} ;
    params.doctyid=srcDoctypeId;
    if (criteriakey !== undefined) {
        params.criteriakey = criteriakey;
    }

    return params
}

export {getDefaultCriteriaByFieldType, getSearchFieldsMatchingParam, 
    getSearchFieldSourceByDataType,
    buildSearchCriteria, buildDynamicGroupKeyValue, buildCriteriaValueFromJsonList, buildSearchCriteriaFromString,
    buildGroupsKeyValueArray, destructDynamicGroupKey, buildFieldsKeyValueArray, buildCondKeyValueArray, buildGroupsChoiceKeyValueArray, saveFavCriteria, searchRecords, restoreSearchState, deleteFavourite };


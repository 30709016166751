import React,{useEffect} from 'react'
import { buildGroupsChoiceKeyValueArray } from './DocSCFunctions'
import DocSearchDynamicGroupChoiceRow from './DocSearchDynamicGroupChoiceRow'
import SarasTabs from '../../../../library/repeater/tabs/SarasTabs';
import DocSearchBirdEyeView from './DocSearchBirdEyeView';

const DocSearchDynamicGroupChoice = (props) => {
    const { viewData, previousChoiceKey, onGroupChoiceSelected } = props;
    const [groupList, setGroupList] = React.useState([]);
    const [currentChoiceKey, setCurrentChoiceKey] = React.useState(previousChoiceKey);
    const [popupName, setPopupName] = React.useState('');
    const [popupAnchor, setPopupAnchor] = React.useState(null);
    const [popupRowKey, setPopupRowKey] = React.useState('');

    //execute whenever field dt changes - effectively this also will run only once
    const initialiseOnDataChange = () => {
        //get the fields list key value pair for the given field dt
        let groupsKeyVal = buildGroupsChoiceKeyValueArray(viewData);
        setGroupList(groupsKeyVal.list);

        //set default only when there is no other group selected
        if(!currentChoiceKey || currentChoiceKey !== (previousChoiceKey || groupsKeyVal.defaultKey)){
            setCurrentChoiceKey(previousChoiceKey || groupsKeyVal.defaultKey);
        }
        // console.log("search groups", groupsKeyVal);
    }

    //execute whenever field dt changes - effectively this also will run only once
    useEffect(initialiseOnDataChange, [viewData, previousChoiceKey]);

    const onGroupChoiceChange = (key, row) => {
        // console.log("dynamicgroup choice selected", key);

        setCurrentChoiceKey(key);
        
        if(onGroupChoiceSelected) onGroupChoiceSelected(row.key, row.val);
    }

    const onDynamicGroupChoiceItemAction = (anchorRef, row) => {
        // console.log("dynamicgroup choice on hover", row.rowKey);

        //show the popup
        setPopupAnchor(anchorRef);
        setPopupName("showBirdEyeView");
        setPopupRowKey(row.rowKey);
    }

    const renderPopup = () => {
        if (popupAnchor == null) return null;

        if (popupName === "showBirdEyeView"){
            return (
                <DocSearchBirdEyeView targetRef={popupAnchor} 
                    birdEyeViewData={viewData[popupRowKey]} 
                    title={viewData[popupRowKey].dataList.label} 
                    isRow={true} onCancel={setPopupName} />
            );
        }

        return null;
    }

    if(!groupList || groupList.length === 0){
        return null;
    }

    return (
        <>
            <SarasTabs defaultKey={currentChoiceKey} enableHotKeys={false} onChange={onGroupChoiceChange} onItemAction={onDynamicGroupChoiceItemAction} dataList={groupList} 
            tabItem={DocSearchDynamicGroupChoiceRow} enableScroll={true} scrollStyle={{height:'40px'}}></SarasTabs>
            {renderPopup()}
        </>
    )
}

export default DocSearchDynamicGroupChoice
import React, { useEffect, useRef } from 'react';
import SarasHotKeys from '../../notifications/SarasHotKeys';
import SarasTrans from '../../notifications/SarasTrans';
import * as fn from "../../../helper/uiFunctions";

//This component will require key value pair of data
const SarasTabsRow = (props) => {
    const { row, index, isActive, onClick, enableHotKeys, hotKeyPrefix, tabItem, enableScroll, onItemAction } = props;
    const liRef = useRef();

    let cssStyle = row.cssStyle ? JSON.parse(row.cssStyle) : {};

    const onTabClick = (e) => {
        let tabKey = row.key;

        if (onClick) onClick(e, tabKey, row);
    }

    const onHotkeyPress = (e) => {
        liRef.current.click();
    }

    const getHotKey = () => {
        if (enableHotKeys)
            if(hotKeyPrefix) return `${hotKeyPrefix}+${index}`
            else return index;

        return null;
    }

    const renderTabItem = () => {
        if(tabItem) {
            let ChildTabItem = tabItem;

            return (
                <ChildTabItem onItemAction={onItemAction} viewData={row} />
            )
        }
        else{
            return (
                <SarasTrans value={row.val} />
            )
        }
    }

    return (
        <SarasHotKeys ksc={getHotKey()} onHotKey={onHotkeyPress} >
            <li ref={liRef} tabKey={row.key} key={index}
                onClick={onTabClick}
                className={`uiCornerTop uiTab ${isActive ? 'uiTabActive' : ''} ${enableScroll ? 'uiScrollItem' : ''}`}
                style = {isActive ? {} : cssStyle}
            >
                <span className="uiTabAnchor">
                    {renderTabItem()}
                </span>
            </li>
        </SarasHotKeys>
    )
}

const SarasTabs = (props) => {
    const { dataList, onChange, defaultKey, kscStartAt, enableHotKeys, hotKeyPrefix, showKeys, tabItem,
        enableScroll, scrollStyle, onItemAction } = props;
    const [activeTabKey, setActiveTabKey] = React.useState(null);

    const initiliaseActiveTab = () => {
        setActiveTabKey(defaultKey);
    }

    useEffect(initiliaseActiveTab, [defaultKey]);

    //raise event only if a different tab was clicked 
    //- to ensure api calls are triggered only for a change and not for every click
    const onTabClick = (e, tabKey, row) => {
        if (activeTabKey !== tabKey) {
            setActiveTabKey(tabKey);
            // console.log("on tab click parent inside if", tabKey);
            if (onChange) onChange(tabKey, row);
        }
    }

    const renderTabRow = (row, index) => {
        let isDisplayTab = true; 
        // console.log("data ", row.showKey)
        if (row.showKey && showKeys){
            let keysList = row.showKey.split(",");
            let commonKeys = keysList.filter(x => showKeys.includes(x));
            if(commonKeys.length === 0)
            {
                isDisplayTab = false;
            }
        }

        if(isDisplayTab)
        {
            return (
                <SarasTabsRow row={row} key={index} index={index + (kscStartAt || 1)}
                    isActive={(row.key === (activeTabKey || (fn.compareNumber(row.isDefault, 1) ? row.key : "")))}
                    onClick={onTabClick} onItemAction={onItemAction} 
                    enableHotKeys={enableHotKeys} hotKeyPrefix={hotKeyPrefix} 
                    tabItem={tabItem} enableScroll={enableScroll}/>
            )
        }
    }

    if (dataList !== undefined && dataList != null) {
        return (
            <div className={`uiTabContainer ${enableScroll ? 'uiHorizontalScroll' : '' }`}
            style={scrollStyle}>
                <div className="uiTabs">
                    <ul role="tablist" className='uiTabsNav uiCornerAll'>
                        {
                            dataList.map((row, index) => (
                                renderTabRow(row, index)
                            ))
                        }
                    </ul>
                </div>
            </div>
        )
    }
    else {
        return null;
    }
}

export default SarasTabs;

import React from 'react';
import SarasDatePicker from '../../input/datePicker/SarasDatePicker'
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import SarasPopup from '../../container/popup/SarasPopup';

const SarasSearchAC = (props) => {
  const { onSearchParamChange, haveDateFieldFilter, inputRef, onSelection, resetSearchParam } = props;

  const [open, setOpen] = React.useState(false);
  const [searchParam, setSearchParam] = React.useState("");
  const [fieldList, setFieldList] = React.useState([]);

  const anchorRef = React.useRef(null);

  const haveDateFilter = haveDateFieldFilter();

  let initialiseSearchParam = function() {
    setSearchParam("");

    if(inputRef) inputRef.current.focus();
  }

  React.useEffect(initialiseSearchParam, [resetSearchParam]);


  const handleSelection = (event) => {
    let fieldKey = event.currentTarget.getAttribute('fieldkey');
    // console.log("clicked", fieldKey, searchParam);
    if(onSelection) onSelection(searchParam, fieldKey);
    setSearchParam("");
    setOpen(false);
    if(inputRef) inputRef.current.focus();
  };

  const handleClose = (event) => {
    setOpen(false);
  };

  const getFilteredFields = (sp) => {
    if(onSearchParamChange && sp.length > 0) {
        let fl = onSearchParamChange(sp);
        // console.log("field list returned", fl);
        setFieldList(fl);
        setOpen(true);
    }
  }

  const onSearchChange = (e) => {
    let sp = e.currentTarget.value;
    // console.log("search change", sp);
    setSearchParam(sp);
    getFilteredFields(sp);
  }

  const setDatePickerValue = (datePickerRef,dateValue) => {
    // console.log("date picker", dateValue);
    setSearchParam(dateValue);
    getFilteredFields(dateValue);
  }

  const onSearchFocus = (e) => {
      //open the field list on focus and if have some value in the search input
      if(searchParam.length > 0 && !open)
      {
        setOpen(true);
      }
  }

  return (
      <>
        <div style={{display:"inline-block"}}>
          <div
            ref={anchorRef}
            aria-controls={open ? 'menu-list-grow' : undefined}
            aria-haspopup="true"
          >
            <input ref={inputRef} type="text" placeholder="Search..." onFocus={onSearchFocus} onChange={onSearchChange} maxLength="100" value={searchParam}></input>
            {haveDateFilter ? 
              <SarasDatePicker id='srcSearchDate' format="dd-MMM-yyyy" className='uiHideMuiDatepickerTxt'  onChange={setDatePickerValue} ></SarasDatePicker>
              : null
            }
            </div>
        </div>
        <SarasPopup anchorRef={anchorRef} open={open} onClose={handleClose} paperStyle={{maxHeight: "300px", overflow:"auto"}}>
          <MenuList autoFocusItem={false} id="menu-list-grow" >
            {fieldList.map((row, index)=>(
                <MenuItem key={index} onClick={handleSelection} fieldkey={row.rowKey}>
                    {row.dataList.label}
                </MenuItem>
            ))}
          </MenuList>
        </SarasPopup>
      </>
  );
}

export default SarasSearchAC;